import { ChainId } from '@/sdk/constants';
import networks from '@/helpers/networks';
import { SupportedNetworkMode, SUPPORTED_NETWORK_MODE } from './SUPPORTED_NETWORK_MODE';
import { ENABLE_CROSS_CHAIN_NETWORK_IDS } from './ENABLE_CROSS_CHAIN_NETWORK_IDS';

type NetworksSupportingConfig = Record<SupportedNetworkMode, ChainId[]>;
type NetworkOptions = typeof networks[ChainId.MILKOMEDA_CARDANO_MAINNET];

export const DEFAULT_NETWORK_NAME = 'Cardano';

export const NETWORK_OPTIONS = {
  testnet: [
    {
      name: networks[ChainId.MILKOMEDA_CARDANO_TESTNET].name,
      value: 'Cardano',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_ALGORAND_TESTNET].name,
      value: 'Algorand',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_KAVA_TESTNET].name,
      value: 'Kava',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_POLYGON_TESTNET].name,
      value: 'Polygon',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_NEON_TESTNET].name,
      value: 'Neon',
      crossSite: {
        prefix: '',
      },
    },
  ],
  mainnet: [
    {
      name: networks[ChainId.MILKOMEDA_CARDANO_MAINNET].name,
      value: 'Cardano',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_ALGORAND_MAINNET].name,
      value: 'Algorand',
      crossSite: {
        prefix: 'MILKOMEDA_',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_KAVA_MAINNET].name,
      value: 'Kava',
      crossSite: {
        prefix: '',
      },
    },
    {
      name: networks[ChainId.MILKOMEDA_POLYGON_MAINNET].name,
      value: 'Polygon',
      crossSite: {
        prefix: '',
      },
    },
  ],
};

export const CROSS_CHAIN_NETWORK_LIST_DROPDOWN_OPTIONS: NetworkOptions[] =
  ENABLE_CROSS_CHAIN_NETWORK_IDS.map(chainId => networks[chainId]);

export const NETWORKS_SUPPORTING_BRIDGE: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: [ChainId.MILKOMEDA_CARDANO_TESTNET],
      MAINNET: [ChainId.MILKOMEDA_CARDANO_MAINNET],
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTING_FARMS: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: [
        ChainId.MILKOMEDA_ALGORAND_TESTNET,
        ChainId.MILKOMEDA_CARDANO_TESTNET,
        ChainId.MILKOMEDA_KAVA_TESTNET,
        ChainId.MILKOMEDA_POLYGON_TESTNET,
        ChainId.MILKOMEDA_NEON_TESTNET,
      ],
      MAINNET: [
        ChainId.MILKOMEDA_ALGORAND_MAINNET,
        ChainId.MILKOMEDA_CARDANO_MAINNET,
        ChainId.MILKOMEDA_KAVA_MAINNET,
      ],
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);

export const NETWORKS_SUPPORTING_YIELD_POOLS: readonly ChainId[] = Object.freeze(
  (
    {
      TESTNET: [
        ChainId.MILKOMEDA_ALGORAND_TESTNET,
        ChainId.MILKOMEDA_CARDANO_TESTNET,
        ChainId.MILKOMEDA_KAVA_TESTNET,
        ChainId.MILKOMEDA_POLYGON_TESTNET,
        ChainId.MILKOMEDA_NEON_TESTNET,
      ],
      MAINNET: [
        ChainId.MILKOMEDA_ALGORAND_MAINNET,
        ChainId.MILKOMEDA_CARDANO_MAINNET,
        ChainId.MILKOMEDA_KAVA_MAINNET,
      ],
    } as NetworksSupportingConfig
  )[SUPPORTED_NETWORK_MODE],
);
