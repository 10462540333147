import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import {
  getErc20Contract,
  getPortfolioAndPairRegistryContract,
  getRouterContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import { Portfolio } from '@/sdk/entities/portfolio';
import { applySlippageInPercents } from '@/sdk/utils';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import {
  fetchFees,
  fetchPortfolioTokensOwned,
  fetchPrices,
  fetchTotalSupply,
  fetchVolumes,
} from '@/store/modules/portfolios/portfolio-api';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Pair } from '@/sdk/entities/pair';
import { BIG_ZERO, ethersToBigNumber, toBigNumber } from '@/utils/bigNumber';
import { Token } from '@/sdk/entities/token';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getRouterAddress } from '@/helpers/address.helper';
import { ChainId, SOLIDITY_TYPE_MAXIMA, SolidityType, BOT_USD_DECIMALS } from '@/sdk/constants';
import { getPortfolioInfos } from '@/helpers/cross-chain-api';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { PortfolioCrossChainDtoInterface } from '@/store/modules/portfolios/models/portfolio-crosschain-dto.interface';
import { PortfolioCrossChain } from '@/sdk/entities/portfolio-crosschain';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { ENABLE_CROSS_CHAIN } from '@/helpers/crossChainEnv.helper';
import { ethers } from 'ethers';
import { getWithdrawMethodParameters } from '@/helpers/portfolioWithdraw.helper';
import { getDepositMethodParameters } from '@/helpers/portfolioDeposit.helper';
import BigNumber from 'bignumber.js';
import { useTokens } from '@/store/modules/tokens/useTokens';

const CONSOLE_LABEL_GREEN = 'color: white; background-color: #95B46A';

export interface PortfolioState {
  portfolio: Portfolio[];
}

export const PORTFOLIO_ACTION_TYPES = {
  INIT_PORTFOLIOS: 'initPortfolios',
  UPDATE_PORTFOLIOS_INFO: 'updatePortfoliosInfo',
  GET_PORTFOLIO_INFO: 'getPortfolioInfo',
  GET_PORTFOLIO_CROSS_CHAIN_INFO: 'getPortfolioCrossChainInfo',
  GET_ALL_PORTFOLIO_INFO: 'getAllPortfolioInfo',
  GET_PORTFOLIO_STATISTICS: 'getPortfolioStatistics',
  PORTFOLIO_ADD_LIQUIDITY: 'portfolioAddLiquidity',
  PORTFOLIO_WITHDRAW: 'portfolioWithdraw',
  PORTFOLIO_CHECK_ALLOWANCE: 'portfolioCheckAllowance',
  PORTFOLIO_APPROVE: 'portfolioApprove',
  SET_DEPOSIT_SETTINGS: 'setDepositSettings',
  SET_WITHDRAW_SETTINGS: 'setWithdrawSettings',
  SET_PORTFOLIOS_MODE: 'setPortfoliosMode',
};
export const PORTFOLIO_MUTATION_TYPES = {
  SET_STATE: 'setState',
  ADD_PORTFOLIO: 'addPortfolio',
};

const state = {
  isStoreReady: false,
  isStatisticLoaded: false,
  isPortfoliosUpdated: true,
  portfolios: {},
  portfoliosMode: localStorage.getItem('portfoliosMode') || 'new',
  depositSettings: {
    slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
    transactionDeadline: TRANSACTION_DEADLINE.toString(),
  },
  withdrawSettings: {
    slippageTolerance: SLIPPAGE_TOLERANCE.toString(),
    transactionDeadline: TRANSACTION_DEADLINE.toString(),
  },
};
const getters = {
  getAllPairs: state => {
    const portfoliosArr: Portfolio[] = Object.values(state.portfolios) ?? [];
    const pairs: Pair[] = portfoliosArr.flatMap(portfolio => Object.values(portfolio.pairs));
    return pairs || [];
  },
  getPortfolios: state => {
    return Object.values(state.portfolios);
  },
};
const actions = {
  [PORTFOLIO_ACTION_TYPES.SET_PORTFOLIOS_MODE]({ commit }, mode: 'new' | 'old') {
    localStorage.setItem('portfoliosMode', mode);
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, { portfoliosMode: mode });
  },
  async [PORTFOLIO_ACTION_TYPES.GET_ALL_PORTFOLIO_INFO]({ dispatch }) {
    console.group('PORTFOLIO_ACTION_TYPES.GET_ALL_PORTFOLIO_INFO');
    await dispatch(PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_INFO);
    await dispatch(PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS);
    console.groupEnd();
  },
  async [PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS]({ commit, dispatch }) {
    console.log('PORTFOLIO_ACTION_TYPES.INIT_PORTFOLIOS');
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, {
      isStoreReady: false,
      isStatisticLoaded: false,
      portfolios: {},
    });
    await dispatch(PORTFOLIO_ACTION_TYPES.GET_ALL_PORTFOLIO_INFO);
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, { isStoreReady: true });
    console.log('STATE.PORTFOLIOS', state.portfolios);
  },
  async [PORTFOLIO_ACTION_TYPES.UPDATE_PORTFOLIOS_INFO]({ commit, dispatch }) {
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, {
      isPortfoliosUpdated: false,
    });
    await dispatch(PORTFOLIO_ACTION_TYPES.GET_ALL_PORTFOLIO_INFO);
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, {
      isPortfoliosUpdated: true,
    });
  },
  async [PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_INFO]({ state, rootState, commit, dispatch }) {
    console.log('%c PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_INFO ', CONSOLE_LABEL_GREEN);
    try {
      const currentChain = rootState.wallet.network?.chainId ?? DEFAULT_NETWORK_ID;
      console.log('get currentChain', currentChain);
      // TODO: when add USD decimals in env, can get USD token
      // const baseUSDToken = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN];
      const botUSDToken = new Token(
        currentChain,
        ethers.constants.AddressZero,
        BOT_USD_DECIMALS,
        'USD',
      );
      const portfolioContract = getPortfolioAndPairRegistryContract();
      const portfoliosResult = await portfolioContract.getPortfolios();
      console.log('portfolioResult', portfoliosResult);

      portfoliosResult.forEach(info => {
        const storedPortfolio: Portfolio = state.portfolios[info.contractAddress];
        if (storedPortfolio) {
          storedPortfolio.updatePortfolioInfo(info);
          console.log(storedPortfolio.contractAddress, info);
        } else {
          const portfolio = new Portfolio(info, PortfolioSource.PORTFOLIO_LOCALE, currentChain);
          console.log('local portfolios', portfolio);
          portfolio.addPriceUSDToken(botUSDToken);

          commit(PORTFOLIO_MUTATION_TYPES.ADD_PORTFOLIO, portfolio);
        }
      });
      await dispatch(PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_CROSS_CHAIN_INFO);
      await Promise.resolve(state.portfolios);
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_CROSS_CHAIN_INFO]({ rootState, commit }) {
    if (!ENABLE_CROSS_CHAIN) return;
    const currentChain = rootState.wallet.network?.chainId ?? DEFAULT_NETWORK_ID;
    // TODO: when add USD decimals in env, can get USD token
    // const baseUSDToken = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN];
    const botUSDToken = new Token(
      currentChain,
      ethers.constants.AddressZero,
      BOT_USD_DECIMALS,
      'USD',
    );
    console.log('get currentChain', currentChain);
    const crosschainPortfoliosInfo = await getPortfolioInfos(currentChain);
    console.log('crosschain portfolios', crosschainPortfoliosInfo.PortfolioInfo);
    const crosschainPortfoliosArray =
      crosschainPortfoliosInfo.PortfolioInfo as PortfolioCrossChainDtoInterface[];
    crosschainPortfoliosArray.forEach(portfolio => {
      const storedPortfolio = state.portfolios[portfolio.contractAddress] as Portfolio;
      if (storedPortfolio) {
        console.log('update crosschain', portfolio);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        storedPortfolio.updatePortfolioInfo(portfolio);

        // NOTE: moved from `GET_PORTFOLIO_STATISTICS`
        storedPortfolio.addTotalSupply(ethersToBigNumber(portfolio.lpTokenTotalSupply));
      } else {
        const preparedCHPortfolio =
          PortfolioCrossChain.createLocalPortfolioFromCrosschain(portfolio);
        console.log('preparedCHPortfolio', preparedCHPortfolio);
        const chPortfolio = new Portfolio(
          preparedCHPortfolio,
          PortfolioSource.PORTFOLIO_CROSSCHAIN,
          +currentChain,
        );
        chPortfolio.addPriceUSDToken(botUSDToken);
        // NOTE: moved from `GET_PORTFOLIO_STATISTICS`
        chPortfolio.addTotalSupply(ethersToBigNumber(portfolio.lpTokenTotalSupply));
        console.log('chPortfolio', chPortfolio);
        commit(PORTFOLIO_MUTATION_TYPES.ADD_PORTFOLIO, chPortfolio);
      }
    });

    //await dispatch(
    //         MODULE_NAMES.ROUTES + '/' + ROUTES_ACTION_TYPES.CALCULATE_ROUTES_FROM_IN,
    //         {
    //           currencyAmountA: tokenAmount,
    //           tokenB: state[getInverseField(notEstimatedFieldType)].token,
    //           tradeOptions,
    //         },
    //         { root: true },
    //       );
  },
  async [PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS]({ state, rootState, commit }) {
    console.group('PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS');
    const { getUSDTokenAddress } = useTokens();
    try {
      const portfolios = state.portfolios;
      const portfoliosAddresses = Object.keys(portfolios);
      const USDTokenAddress = getUSDTokenAddress();
      const portfolioBaseTokenToPriceMap = {};
      Object.values<Portfolio>(portfolios).forEach(portfolio => {
        portfolioBaseTokenToPriceMap[portfolio.baseTokenAddress] = BIG_ZERO;
      });
      const portfolioBaseTokens = Object.keys(portfolioBaseTokenToPriceMap);
      const lpTokenAddresses = Object.values<Portfolio>(portfolios).map(
        portfolio => portfolio.lpTokenAddress,
      );
      const userAddress = rootState.wallet.account;

      const [volumes, fees, prices, rawTotalSupply, rawPortfolioTokensOwned] = await Promise.all([
        fetchVolumes(portfoliosAddresses),
        fetchFees(portfoliosAddresses),
        fetchPrices(portfolioBaseTokens, USDTokenAddress),
        fetchTotalSupply(lpTokenAddresses),
        userAddress ? fetchPortfolioTokensOwned(lpTokenAddresses, userAddress) : null,
      ]);

      portfolioBaseTokens.forEach(
        (portfolioBaseTokenAddress, index) =>
          (portfolioBaseTokenToPriceMap[portfolioBaseTokenAddress] = toBigNumber(prices[index])),
      );

      // console.log('volumeResponse', volumes);
      // console.log('feeResponse', fees);
      // console.log('pricesResponse', prices);
      // console.log('rawPortfolioTokensOwned', rawPortfolioTokensOwned);

      Object.keys(portfolios).forEach((addr, index) => {
        const portfolio = portfolios[addr];

        // NOTE: total supply for cross chain portfolio will be set into `GET_PORTFOLIO_CROSS_CHAIN_INFO`.
        if (portfolio.type === PortfolioSource.PORTFOLIO_LOCALE) {
          portfolio.addTotalSupply(ethersToBigNumber(rawTotalSupply[index]));
        }
        if (userAddress) {
          portfolio.addBalanceOfWallet(ethersToBigNumber(rawPortfolioTokensOwned![index][0]));
        }
        portfolio.addVolume(volumes[index]);
        portfolio.addFee(fees[index]);
        portfolio.addPriceInUSD(portfolioBaseTokenToPriceMap[portfolios[addr].baseTokenAddress]);
      });
      commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, { isStatisticLoaded: true });
    } catch (e) {
      console.debug('[PORTFOLIO_ACTION_TYPES.GET_PORTFOLIO_STATISTICS:ERROR] Error: ', e);
    }
    console.groupEnd();
  },

  async [PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE](
    { rootState, dispatch },
    { token, amountInWei = BIG_ZERO, sendApprove = true },
  ): Promise<boolean> {
    try {
      if (token.isBaseToken() || token.isWETHToken()) {
        return false;
      }

      const tokenContract = getErc20Contract(token.address, getInstance()?.web3.getSigner());
      const allowance = await tokenContract.allowance(rootState.wallet.account, getRouterAddress());
      const allowanceInWei = ethersToBigNumber(allowance);
      const needApprove = allowanceInWei.isZero() || allowanceInWei.lt(amountInWei);

      if (sendApprove && needApprove) {
        await dispatch(PORTFOLIO_ACTION_TYPES.PORTFOLIO_APPROVE, { token });
      }

      return needApprove;
    } catch (e) {
      console.debug(e);
      throw e;
    }
  },
  async [PORTFOLIO_ACTION_TYPES.PORTFOLIO_APPROVE](_, { token }) {
    try {
      if (token.isBaseToken() || token.isWETHToken()) {
        return;
      }

      const tokenContract = getErc20Contract(token.address, getInstance()?.web3.getSigner());

      const result = await transactionWithEstimatedGas(tokenContract, 'approve', [
        getRouterAddress(),
        SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
      ]);

      await result.wait();
    } catch (e) {
      console.debug(e);
      throw e;
    }
  },
  async [PORTFOLIO_ACTION_TYPES.PORTFOLIO_ADD_LIQUIDITY](
    { state, rootState, dispatch },
    {
      selectedTokens,
      portfolio,
      lpAmountOut,
      destinationChainId,
    }: {
      selectedTokens: SelectedPortfolioTokens;
      portfolio: Portfolio;
      lpAmountOut: string;
      destinationChainId: ChainId;
    },
  ): Promise<ethers.providers.TransactionReceipt> {
    const { tokens, amountsIn, portfolioAddress } = getDepositMethodParameters(
      portfolio,
      selectedTokens,
    );

    const needApproveFlags = await Promise.all(
      tokens.map((token, index) =>
        dispatch(PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE, {
          token,
          amountInWei: amountsIn[index],
          sendApprove: false,
        }),
      ),
    );

    for (let i = 0; i < needApproveFlags.length; i++) {
      if (!needApproveFlags[i]) {
        continue;
      }

      await dispatch(PORTFOLIO_ACTION_TYPES.PORTFOLIO_APPROVE, { token: tokens[i] });
    }

    const minAmountOut = applySlippageInPercents(
      lpAmountOut,
      state.depositSettings.slippageTolerance,
    ).toFixed(0);

    const nativeTokenIndex = tokens.findIndex(token => token.isBaseToken());
    const msgValue = nativeTokenIndex === -1 ? 0 : amountsIn[nativeTokenIndex];

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const overrides = {
      value: msgValue,
    };

    console.log(
      'addLiquidityToPortfolio',
      [
        tokens.map(token => token.address),
        amountsIn,
        minAmountOut,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.depositSettings.transactionDeadline),
        portfolioAddress,
        portfolio.type === PortfolioSource.PORTFOLIO_LOCALE ? 0 : destinationChainId,
      ],
      overrides,
    );

    const result = await transactionWithEstimatedGas(
      routerContract,
      'addLiquidityToPortfolio',
      [
        tokens.map(token => token.address),
        amountsIn,
        minAmountOut,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.depositSettings.transactionDeadline),
        portfolioAddress,
        portfolio.type === PortfolioSource.PORTFOLIO_LOCALE ? 0 : destinationChainId,
      ],
      overrides,
    );

    return await result.wait();
  },
  // OLD UI
  async [PORTFOLIO_ACTION_TYPES.PORTFOLIO_WITHDRAW](
    { state, rootState, dispatch },
    {
      portfolio,
      tokenAmounts,
    }: {
      portfolio: Portfolio;
      tokenAmounts: string[];
    },
  ) {
    const { lptAmounts, tokenAddresses, portfolioAddress } = getWithdrawMethodParameters(portfolio);

    const minAmountsOut: string[] = tokenAmounts.map(amount =>
      applySlippageInPercents(amount, state.withdrawSettings.slippageTolerance).toFixed(0),
    );

    const lpToken: Token = new Token(
      portfolio.baseToken.chainId,
      portfolio.lpTokenAddress,
      18,
      'LPT',
    );
    await dispatch(PORTFOLIO_ACTION_TYPES.PORTFOLIO_CHECK_ALLOWANCE, {
      token: lpToken,
      amountInWei: BigNumber.max(...lptAmounts) ?? BIG_ZERO,
    });

    const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());

    const result = await transactionWithEstimatedGas(
      routerContract,
      'removeLiquidityFromPortfolio(address[],uint256[],uint256[],address,uint256,address)',
      [
        tokenAddresses,
        lptAmounts,
        minAmountsOut,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.withdrawSettings.transactionDeadline),
        portfolioAddress,
      ],
    );

    await result.wait();
  },
  async [PORTFOLIO_ACTION_TYPES.SET_DEPOSIT_SETTINGS]({ commit }, { settings }) {
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, { depositSettings: settings });
  },
  async [PORTFOLIO_ACTION_TYPES.SET_WITHDRAW_SETTINGS]({ commit }, { settings }) {
    commit(PORTFOLIO_MUTATION_TYPES.SET_STATE, { withdrawSettings: settings });
  },
};
const mutations = {
  [PORTFOLIO_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [PORTFOLIO_MUTATION_TYPES.ADD_PORTFOLIO](_state, payload: Portfolio) {
    _state.portfolios[payload.contractAddress] = payload;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
