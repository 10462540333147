import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import {
  BLOCKCHAINS,
  getBlockchainById,
  getBlockchainByName,
} from '@/store/modules/bridge/constants/BLOCKCHAINS';
import type { ChainName } from 'crypto-sdk';

export function isCardanoMainnetOrTestnet(blockchainName: BLOCKCHAIN_NAME | number): boolean {
  if (typeof blockchainName === 'number') {
    try {
      blockchainName = getBlockchainById(blockchainName).name;
    } catch {
      return false;
    }
  }

  return (
    blockchainName === BLOCKCHAIN_NAME.CARDANO || blockchainName === BLOCKCHAIN_NAME.TESTNET_CARDANO
  );
}

export function isMilkomedaMainnetOrTestnet(blockchainName: BLOCKCHAIN_NAME | number): boolean {
  if (typeof blockchainName === 'number') {
    try {
      blockchainName = getBlockchainById(blockchainName).name;
    } catch {
      return false;
    }
  }

  return (
    blockchainName === BLOCKCHAIN_NAME.MILKOMEDA ||
    blockchainName === BLOCKCHAIN_NAME.TESTNET_MILKOMEDA
  );
}

export function getCurrentCardanoBlockchain():
  | BLOCKCHAIN_NAME.CARDANO
  | BLOCKCHAIN_NAME.TESTNET_CARDANO {
  return BLOCKCHAINS[
    Number(process.env.VUE_APP_DEFAULT_CARDANO_ID) ||
      getBlockchainByName(BLOCKCHAIN_NAME.CARDANO).id
  ];
}

export function getBridgeBlockchainName(blockchainName: BLOCKCHAIN_NAME): ChainName {
  const mapping: Record<BLOCKCHAIN_NAME, string> = {
    [BLOCKCHAIN_NAME.CARDANO]: 'Cardano',
    [BLOCKCHAIN_NAME.TESTNET_CARDANO]: 'Cardano Testnet',
    [BLOCKCHAIN_NAME.MILKOMEDA]: 'Milkomeda',
    [BLOCKCHAIN_NAME.TESTNET_MILKOMEDA]: 'Milkomeda Devnet',
  };

  return mapping[blockchainName] as ChainName;
}
