import { DEFAULT_CHAIN_ID } from '@/constants/DEFAULT_CHAIN_ID';
import { ethers } from 'ethers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import networks from '@/helpers/networks';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';

const baseNetworkId = DEFAULT_NETWORK_ID ?? DEFAULT_CHAIN_ID;
const baseNetRpc = networks[baseNetworkId].rpc;
const net = baseNetRpc[baseNetRpc.length - 1].toString();

export const simpleRpcProvider = new ethers.providers.JsonRpcProvider(getInstance()?.web3 ?? net);

export default null;
