import liquifiTokens from '@/data/lists/liquifi.tokenlist.json';
import { Token } from '@/sdk/entities/token';
import { BN_ZERO, ethersToJSBI } from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { GLOBAL_GETTERS } from '@/store/modules/global.getters';
import { NETWORK_BACKEND_URL, NETWORK_USD_TOKEN_ADDRESS } from '@/helpers/networkParams.helper';
import networks from '@/helpers/networks';

export enum FetchStatus {
  NOT_FETCHED = 'not-fetched',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export const TOKENS_ACTION_TYPES = {
  LOAD_TOKENS_LISTS: 'loadTokensLists',
  LOAD_TOKENS_FROM_SELECTED_LIST_BY_NETWORK_ID: 'loadTokensFromSelectedListByNetworkId',
  PRELOAD_BASE_TOKEN: 'preloadBaseToken',
  INIT_TOKEN_PRICES: 'initTokenPrices',
  UPDATE_TOKEN_PRICES: 'updateTokenPrices',
  LOAD_TOKENS_FROM_ALL_NETWORKS: 'loadTokensFromAllNetworks',
};

export const TOKENS_MUTATION_TYPES = {
  SET_STATE: 'setState',
  SET_TOKENS: 'setTokens',
  SET_PRICES: 'setPrices',
  UPDATE_PRICE: 'updatePrice',
  SET_ALL_TOKENS: 'setAllTokens',
};

const TOKEN_LISTS = {
  DEFAULT: 'liquifi.tokenlist',
};

const state = {
  allTokens: {},
  tokens: {},
  tokensLists: {},
  prices: {},
  selectedList: TOKEN_LISTS.DEFAULT,
};
const getters = {
  getBaseToken: state => {
    console.warn('[LEGACY] : getBaseToken (need use `useTokens`)');
    const baseUSDToken = Object.values(state.tokens).find(
      token => (token as Token).address === NETWORK_USD_TOKEN_ADDRESS,
    );
    return baseUSDToken;
  },
  getBaseTokenAddress: () => {
    console.warn('[LEGACY] : getBaseTokenAddress (need use `useTokens`)');
    return NETWORK_USD_TOKEN_ADDRESS;
  },
  getNativeToken: state => {
    console.warn('[LEGACY] : getNativeToken (need use `useTokens`)');
    return state.tokens.BLUES;
  },
};
const actions = {
  async [TOKENS_ACTION_TYPES.LOAD_TOKENS_LISTS]({ commit }) {
    console.warn('[LEGACY] : TOKENS_ACTION_TYPES.LOAD_TOKENS_LISTS (uses legacy `liquifiTokens`)');
    await new Promise(resolve => {
      commit(TOKENS_MUTATION_TYPES.SET_STATE, {
        tokensLists: {
          [TOKEN_LISTS.DEFAULT]: liquifiTokens,
        },
      });
      resolve(true);
    });
  },
  async [TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_SELECTED_LIST_BY_NETWORK_ID](
    { state, commit },
    networkId: number,
  ) {
    console.warn(
      '[LEGACY] : TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_SELECTED_LIST_BY_NETWORK_ID (uses legacy `tokensLists`)',
    );
    await new Promise(resolve => {
      const tokenList = state.tokensLists[state.selectedList];
      const tokens = tokenList.tokens
        .filter(t => t.chainId === networkId)
        .map(
          t =>
            new Token(t.chainId, t.address, t.decimals, t.symbol, t.name, t.projectLink, t.logoURI),
        );
      commit(TOKENS_MUTATION_TYPES.SET_TOKENS, tokens);
      resolve(true);
    });
  },
  async [TOKENS_ACTION_TYPES.INIT_TOKEN_PRICES]({ state, commit, dispatch }) {
    console.warn('[LEGACY] : TOKENS_ACTION_TYPES.INIT_TOKEN_PRICES (uses legacy `tokens`)');
    const prices = Object.keys(state.tokens).map(key => {
      return {
        key: state.tokens[key].address,
        price: new TokenAmount(state.tokens[key], BN_ZERO),
        status: FetchStatus.NOT_FETCHED,
      };
    });
    commit(TOKENS_MUTATION_TYPES.SET_PRICES, prices);
    await dispatch(TOKENS_ACTION_TYPES.UPDATE_TOKEN_PRICES);
  },
  async [TOKENS_ACTION_TYPES.UPDATE_TOKEN_PRICES]({ state, commit, rootGetters }) {
    console.warn('[LEGACY] : TOKENS_ACTION_TYPES.UPDATE_TOKEN_PRICES (uses legacy `tokens`)');
    const tokensAddr = Object.keys(state.tokens).map(token => state.tokens[token].address);

    const baseTokenAddress = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN_ADDRESS];

    const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getPrices'}`, {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        signature: false,
        tokens: tokensAddr,
        baseToken: baseTokenAddress,
      }),
    });
    const pricesResponse = await pricesRequest.json();
    console.warn('[LEGACY] : pricesResponse : ', pricesResponse);

    pricesResponse.token.forEach((key, index) => {
      commit(TOKENS_MUTATION_TYPES.UPDATE_PRICE, {
        key: key,
        price: new TokenAmount(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Object.values(state.tokens).find(token => token.address === key),
          ethersToJSBI(pricesResponse.price[index]),
        ),
        status: FetchStatus.SUCCESS,
      });
    });
  },
  async [TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_ALL_NETWORKS]({ commit }) {
    console.warn('[LEGACY] : TOKENS_ACTION_TYPES.LOAD_TOKENS_FROM_ALL_NETWORKS');
    const tokenList = state.tokensLists[state.selectedList];
    const tokens = tokenList.tokens.map(
      t =>
        new Token(
          t.chainId,
          t.address,
          t.decimals,
          t.crossChainSymbol,
          t.name,
          t.projectLink,
          t.logoURI,
        ),
    );
    commit(TOKENS_MUTATION_TYPES.SET_ALL_TOKENS, tokens);
    console.warn('[LEGACY] TOKENS', state.allTokens);
  },
};
const mutations = {
  [TOKENS_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [TOKENS_MUTATION_TYPES.SET_TOKENS](_state, payload) {
    const tokens = {};
    Object.keys(payload).forEach(key => {
      tokens[payload[key].symbol] = payload[key];
    });
    _state['tokens'] = tokens;
  },
  [TOKENS_MUTATION_TYPES.SET_PRICES](_state, payload) {
    const prices = {};
    Object.keys(payload).forEach(key => {
      prices[payload[key].key] = { prices: payload[key].price, status: payload[key].status };
    });
    _state['prices'] = prices;
  },
  [TOKENS_MUTATION_TYPES.UPDATE_PRICE](_state, payload) {
    _state.prices[payload.key] = payload;
  },
  [TOKENS_MUTATION_TYPES.SET_ALL_TOKENS](_state, payload) {
    Object.keys(networks).forEach(networkId => {
      _state.allTokens[networkId] = {};
    });
    payload.forEach(token => {
      _state.allTokens[token.chainId][token.symbol] = token;
    });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
