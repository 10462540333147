import JSBI from 'jsbi';
import { ethers } from 'ethers';
import { BIG_TEN } from '@/utils/bigNumber';
import BigNumber from 'bignumber.js';
import { PairSource, PairSourceType } from './entities/pairSource';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

export type SerializedBigNumber = string;

// exports for external consumption
export type BigintIsh = JSBI | bigint | string;

export type BignumberIsh = BigNumber | bigint | string;

export const ethersToJSBI = (ethersBn: ethers.BigNumber): JSBI => JSBI.BigInt(ethersBn.toString());

export const BN_ZERO = JSBI.BigInt(0);
export const BN_ONE = JSBI.BigInt(1);
export const BN_TWO = JSBI.BigInt(2);
export const BN_THREE = JSBI.BigInt(3);
export const BN_FIVE = JSBI.BigInt(5);
export const BN_TEN = JSBI.BigInt(10);
export const _100 = JSBI.BigInt(100);
export const FEES_NUMERATOR = JSBI.BigInt(997);
export const FEES_DENOMINATOR = JSBI.BigInt(1000);
export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000);

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT,
}

export enum ChainId {
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  ETH_MAINNET = 1,
  ETH_ROPSTEN = 3,
  ETH_RINKEBY = 4,
  ETH_GOERLI = 5,
  ETH_KOVAN = 42,
  MILKOMEDA_CARDANO_TESTNET = 200101,
  MILKOMEDA_CARDANO_MAINNET = 2001,
  CARDANO_MAINNET = 1,
  CARDANO_TESTNET = 0,
  MILKOMEDA_ALGORAND_TESTNET = 200202,
  MILKOMEDA_ALGORAND_MAINNET = 2002,
  MILKOMEDA_KAVA_TESTNET = 2221,
  MILKOMEDA_KAVA_MAINNET = 2222,
  MILKOMEDA_POLYGON_TESTNET = 80001,
  MILKOMEDA_POLYGON_MAINNET = 137,
  BLUES_CHAIN_TESTNET = 999998,
  BLUES_CHAIN_MAINNET = 999999,
  MILKOMEDA_NEON_TESTNET = 245022926,
}

export const BASE_SCAN_URLS = {
  [ChainId.BSC_MAINNET]: 'https://bscscan.com',
  [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: 'https://explorer-devnet-cardano-evm.c1.milkomeda.com',
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: 'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
  [ChainId.CARDANO_MAINNET]: 'https://explorer.cardano.org/en',
  [ChainId.CARDANO_TESTNET]: 'https://explorer.cardano-testnet.iohkdev.io/en',
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: 'https://testnet-algorand-rollup.a1.milkomeda.com',
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: 'https://explorer-mainnet-algorand-rollup.a1.milkomeda.com',
  [ChainId.MILKOMEDA_KAVA_TESTNET]: 'https://explorer.testnet.kava.io',
  [ChainId.MILKOMEDA_KAVA_MAINNET]: 'https://explorer.kava.io',
  [ChainId.MILKOMEDA_POLYGON_TESTNET]: 'https://mumbai.polygonscan.com',
  [ChainId.MILKOMEDA_POLYGON_MAINNET]: 'https://polygonscan.com',
  [ChainId.BLUES_CHAIN_TESTNET]: 'https://', // ToDo when will the explorer start working
  [ChainId.BLUES_CHAIN_MAINNET]: 'https://', // ToDo when will the explorer start working
  [ChainId.MILKOMEDA_NEON_TESTNET]: 'https://devnet.neonscan.org',
};

export const TOKEN_SCAN_URLS = {
  [ChainId.BSC_MAINNET]: '/token/',
  [ChainId.BSC_TESTNET]: '/token/',
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: '/token/',
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: '/token/',
  [ChainId.CARDANO_MAINNET]: '/token/',
  [ChainId.CARDANO_TESTNET]: '/token/',
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '?module=token&address=',
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '/token/',
  [ChainId.MILKOMEDA_KAVA_TESTNET]: '/token/',
  [ChainId.MILKOMEDA_KAVA_MAINNET]: '/token/',
  [ChainId.MILKOMEDA_POLYGON_TESTNET]: '/token/',
  [ChainId.MILKOMEDA_POLYGON_MAINNET]: '/token/',
  [ChainId.MILKOMEDA_NEON_TESTNET]: '/token/',
};

export const TOKEN_TRANSFERS_SCAN_URLS = {
  [ChainId.BSC_MAINNET]: '',
  [ChainId.BSC_TESTNET]: '',
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: '/token-transfers',
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: '/token-transfers',
  [ChainId.CARDANO_MAINNET]: '/token-transfers',
  [ChainId.CARDANO_TESTNET]: '/token-transfers',
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '/token-transfers',
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '/token-transfers',
  [ChainId.MILKOMEDA_KAVA_TESTNET]: '/token-transfers',
  [ChainId.MILKOMEDA_KAVA_MAINNET]: '/token-transfers',
  [ChainId.MILKOMEDA_POLYGON_TESTNET]: '',
  [ChainId.MILKOMEDA_POLYGON_MAINNET]: '',
  [ChainId.MILKOMEDA_NEON_TESTNET]: '',
};

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256',
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt(
    '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
  ),
};

export const NO_CONTRACT_ADDRESS = '0x0000000000000000000000000000000000000000';

export const NO_SOURCE_ID = undefined;

export const LQF_PRICE = 0.01;
export const BLOCKS_PER_YEAR = 8_000_000;
export const PRICE_MULTIPLIER = BIG_TEN.pow(18);
export const WEIGHT_MULTIPLIER = BIG_TEN.pow(4);
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18);
export const PRICE_DECIMALS = 18;
export const RATIO_TO_SHARE = 10_000;
export const CLAIMS_IN_YEAR = 5_000 * 365;
export const DEFAULT_FEE = 0.003;
export const BASE_CURRENCY_FEE = 0.2;
export const FEE_PRECISION = 0.001;
export const MAX_SLIPPAGE_TOLERANCE = 50;
export const MIN_SLIPPAGE_TOLERANCE = 0;
export const MAX_TRANSACTION_DEADLINE = 600;
export const MIN_TRANSACTION_DEADLINE = 1;

export const EMPTY_PAIR_SOURCE: PairSource = {
  type: PairSourceType.EMPTY,
  address: NO_CONTRACT_ADDRESS,
  id: NO_SOURCE_ID,
};

export const TOTAL_BLUES_SUPPLY = 100_000_000;

// TODO: Need remove when will do FSDEX-1887
export const BOT_USD_DECIMALS = 6;
// NOTE: Looks like it is BOT_USD_DECIMALS
export const TVL_DECIMALS = 6;
