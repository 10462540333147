import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';

import { BLOCKS_PER_YEAR, DEFAULT_TOKEN_DECIMAL } from '@/sdk/constants';
import { ethersToBigNumber } from '@/utils/bigNumber';
import { StakingPool } from './stakingPool';
import { useReferral } from '@/store/modules/referral/useReferral';
import { APR_INCREASE_PERCENT } from '@/constants/REFERRAL_PARAMS';

type StatusStakingType = {
  multiplier: ethers.BigNumber;
  rewardPerBlock: ethers.BigNumber;
  accDeposited: ethers.BigNumber;
  deposited: ethers.BigNumber;
  earned: ethers.BigNumber;
  totalReward: ethers.BigNumber;
  token: string;
  farm: string;
};

export class Staking implements StakingPool {
  public readonly multiplier: BigNumber;
  public readonly rewardPerBlock: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly totalStaked: BigNumber;
  public readonly deposited: BigNumber;
  public readonly earned: BigNumber;
  public readonly totalReward: BigNumber;
  public readonly token: string;
  public readonly farm: string;
  public readonly nativeTokenPriceInBase: BigNumber;

  constructor(
    statusStaking: StatusStakingType,
    nativeTokenPriceInBase: BigNumber,
    totalStaked?: ethers.BigNumber,
  ) {
    console.log('Staking.ts', statusStaking);
    this.multiplier = ethersToBigNumber(statusStaking.multiplier);
    this.rewardPerBlock = ethersToBigNumber(statusStaking.rewardPerBlock);
    this.accDeposited = ethersToBigNumber(statusStaking.accDeposited);
    this.totalStaked = ethersToBigNumber(totalStaked || statusStaking.accDeposited);
    this.deposited = ethersToBigNumber(statusStaking.deposited);
    this.earned = ethersToBigNumber(statusStaking.earned);
    console.log('earned', this.earned.toString());
    this.totalReward = ethersToBigNumber(statusStaking.totalReward);
    this.token = statusStaking.token;
    this.farm = statusStaking.farm;
    this.nativeTokenPriceInBase = nativeTokenPriceInBase; // in USD
  }

  public get printApr(): BigNumber {
    let apr = this.rewardPerBlock
      .multipliedBy(BLOCKS_PER_YEAR)
      .dividedBy(this.accDeposited)
      .multipliedBy(100);
    const { referralState } = useReferral();
    apr = referralState.isReferral
      ? apr.multipliedBy(1 + APR_INCREASE_PERCENT.REFERRAL / 100)
      : apr;

    console.log('apr', apr.valueOf());
    return isNaN(Number(apr.valueOf())) ? new BigNumber(0) : apr;
  }

  public get printApy(): BigNumber {
    return new BigNumber('1');
  }

  /**
   * Total stake in BLUES (native pool token)
   */
  public get printTotalStake(): BigNumber {
    // TODO: DEFAULT_TOKEN_DECIMAL - should be BLUES decimals
    return this.totalStaked.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYourTotalReward(): BigNumber {
    return this.totalReward.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  /**
   * Earn in BLUES (native pool token)
   */
  public get printYouEarned(): BigNumber {
    // TODO: DEFAULT_TOKEN_DECIMAL - should be BLUES decimals
    return this.earned.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouEarnedUSD(): BigNumber {
    return this.earned.dividedBy(DEFAULT_TOKEN_DECIMAL).multipliedBy(this.nativeTokenPriceInBase);
  }

  public get printYouStaked(): BigNumber {
    return this.deposited.dividedBy(DEFAULT_TOKEN_DECIMAL);
  }

  public get printYouStakedUSD(): BigNumber {
    return this.deposited
      .dividedBy(DEFAULT_TOKEN_DECIMAL)
      .multipliedBy(this.nativeTokenPriceInBase);
  }

  public calculateUSD(value: string): BigNumber {
    return new BigNumber(value === '' ? 0 : value).multipliedBy(this.nativeTokenPriceInBase);
  }
}
