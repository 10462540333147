import { ChainId } from '@/sdk/constants';

export const BASE_TOKENS = {
  [ChainId.BSC_MAINNET]: {
    chainId: ChainId.BSC_MAINNET,
    address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    decimals: 18,
    symbol: 'BNB',
    name: 'BNB',
    projectLink: 'https://www.binance.org',
    tokenIconUrl: '/images/tokens/bnb.svg',
  },
  [ChainId.BSC_TESTNET]: {
    chainId: ChainId.BSC_TESTNET,
    address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    decimals: 18,
    symbol: 'BNB',
    name: 'BNB',
    projectLink: 'https://www.binance.org',
    tokenIconUrl: '/images/tokens/bnb.svg',
  },
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: {
    chainId: ChainId.MILKOMEDA_CARDANO_TESTNET,
    address: '0x1a40217B16E7329E27FDC9cED672e1F264e07Cc2',
    decimals: 18,
    symbol: 'milkADA',
    name: 'milkADA',
    projectLink: 'https://milkomeda.com',
    tokenIconUrl: '/images/tokens/ada.svg',
  },
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: {
    chainId: ChainId.MILKOMEDA_CARDANO_MAINNET,
    address: '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
    decimals: 18,
    symbol: 'milkADA',
    name: 'milkADA',
    projectLink: 'https://milkomeda.com',
    tokenIconUrl: '/images/tokens/ada.svg',
  },
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: {
    chainId: ChainId.MILKOMEDA_ALGORAND_TESTNET,
    address: '0xb8f56715F3006A010025946351c9e5C30A0797bF',
    decimals: 18,
    symbol: 'milkALGO',
    name: 'milkALGO',
    projectLink: 'https://milkomeda.com',
    tokenIconUrl: '/images/tokens/algo.svg',
  },
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: {
    chainId: ChainId.MILKOMEDA_ALGORAND_MAINNET,
    address: '0xaF86E6c5Fd9dAf53e5100ed38BaB2572609fCA27',
    decimals: 18,
    symbol: 'milkALGO',
    name: 'milkALGO',
    projectLink: 'https://milkomeda.com',
    tokenIconUrl: '/images/tokens/algo.svg',
  },
  [ChainId.MILKOMEDA_KAVA_TESTNET]: {
    chainId: ChainId.MILKOMEDA_KAVA_TESTNET,
    address: '0xFa95D53e0B6e82b2137Faa70FD7E4a4DC70Da449',
    decimals: 18,
    symbol: 'KAVA',
    name: 'KAVA',
    projectLink: 'https://www.kava.io/',
    tokenIconUrl: '/images/tokens/kava.svg',
  },
  [ChainId.MILKOMEDA_KAVA_MAINNET]: {
    chainId: ChainId.MILKOMEDA_KAVA_MAINNET,
    address: '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
    decimals: 18,
    symbol: 'KAVA',
    name: 'KAVA',
    projectLink: 'https://www.kava.io/',
    tokenIconUrl: '/images/tokens/kava.svg',
  },
  [ChainId.MILKOMEDA_POLYGON_TESTNET]: {
    chainId: ChainId.MILKOMEDA_POLYGON_TESTNET,
    address: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    decimals: 18,
    symbol: 'MATIC',
    name: 'MATIC',
    projectLink: 'https://polygon.technology/',
    tokenIconUrl: '/images/tokens/matic.svg',
  },
  [ChainId.MILKOMEDA_POLYGON_MAINNET]: {
    chainId: ChainId.MILKOMEDA_POLYGON_MAINNET,
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    decimals: 18,
    symbol: 'MATIC',
    name: 'MATIC',
    projectLink: 'https://polygon.technology/',
    tokenIconUrl: '/images/tokens/matic.svg',
  },
  [ChainId.MILKOMEDA_NEON_TESTNET]: {
    chainId: ChainId.MILKOMEDA_NEON_TESTNET,
    address: '0x11adC2d986E334137b9ad0a0F290771F31e9517F',
    decimals: 18,
    symbol: 'NEON',
    name: 'NEON',
    projectLink: 'https://neon-labs.org/',
    tokenIconUrl: '/images/tokens/neon.svg',
  },
};
