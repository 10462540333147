export const LAYOUT_ACTION_TYPES = {
  SET_MOBILE: 'setMobile',
  SET_TABLET: 'setTablet',
  SET_DESKTOP: 'setDesktop',
  SET_LOADING_UI: 'setLoadingUi',
};

export const LAYOUT_MUTATION_TYPES = {
  SET_STATE: 'setState',
  SWITCH_MOBILE: 'switchMobile',
  SWITCH_TABLET: 'switchTablet',
  SWITCH_DESKTOP: 'switchDesktop',
};

const state = {
  isMobile: false,
  isTablet: false,
  isDesktop: true,
  loadingUi: true,
  viewMode: 'desktop',
};

const getters = {
  isMobile(state) {
    return state.isMobile;
  },
  isLoadingUi: state => {
    return state.loadingUi;
  },
};

const actions = {
  [LAYOUT_ACTION_TYPES.SET_MOBILE]({ commit }) {
    commit(LAYOUT_MUTATION_TYPES.SWITCH_MOBILE);
  },
  [LAYOUT_ACTION_TYPES.SET_TABLET]({ commit }) {
    commit(LAYOUT_MUTATION_TYPES.SWITCH_TABLET);
  },
  [LAYOUT_ACTION_TYPES.SET_DESKTOP]({ commit }) {
    commit(LAYOUT_MUTATION_TYPES.SWITCH_DESKTOP);
  },
  [LAYOUT_ACTION_TYPES.SET_LOADING_UI]({ commit }, isLoading) {
    commit(LAYOUT_MUTATION_TYPES.SET_STATE, { loadingUi: isLoading });
  },
};

const mutations = {
  [LAYOUT_MUTATION_TYPES.SWITCH_MOBILE]: state => {
    state.isMobile = true;
    state.isTablet = false;
    state.isDesktop = false;
    state.viewMode = 'mobile';
  },
  [LAYOUT_MUTATION_TYPES.SWITCH_TABLET]: state => {
    state.isMobile = false;
    state.isTablet = true;
    state.isDesktop = false;
    state.viewMode = 'tablet';
  },
  [LAYOUT_MUTATION_TYPES.SWITCH_DESKTOP]: state => {
    state.isMobile = false;
    state.isTablet = false;
    state.isDesktop = true;
    state.viewMode = 'desktop';
  },
  [LAYOUT_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
