import { BASE_SCAN_URLS, ChainId } from '@/sdk/constants';

export default {
  [ChainId.ETH_MAINNET]: {
    key: '1',
    name: 'Ethereum Mainnet',
    shortName: 'Mainnet',
    chainId: 1,
    network: 'homestead',
    rpc: [
      {
        url: 'https://api-geth-archive.ankr.com',
        user: 'balancer_user',
        password: 'balancerAnkr20201015',
      },
      'https://eth-archival.gateway.pokt.network/v1/5f76124fb90218002e9ce985',
      'https://eth-mainnet.alchemyapi.io/v2/4bdDVB5QAaorY2UE-GBUbM2yQB3QJqzv',
      'https://cloudflare-eth.com',
    ],
    ws: ['wss://eth-mainnet.ws.alchemyapi.io/v2/4bdDVB5QAaorY2UE-GBUbM2yQB3QJqzv'],
    explorer: 'https://etherscan.io',
  },
  [ChainId.ETH_ROPSTEN]: {
    key: '3',
    name: 'Ethereum Testnet Ropsten',
    shortName: 'Ropsten',
    chainId: 3,
    network: 'ropsten',
    rpc: ['https://eth-ropsten.alchemyapi.io/v2/wbsH-Ihl4guFWnM4klEJxh5r64KRp3LV'],
    explorer: 'https://ropsten.etherscan.io',
  },
  [ChainId.ETH_RINKEBY]: {
    key: '4',
    name: 'Ethereum Testnet Rinkeby',
    shortName: 'Rinkeby',
    chainId: 4,
    network: 'rinkeby',
    rpc: [
      'https://eth-rinkeby.alchemyapi.io/v2/twReQE9Px03E-E_N_Fbb3OVF7YgHxoGq',
      'https://eth-rinkeby.gateway.pokt.network/v1/5f76124fb90218002e9ce985',
    ],
    ws: ['wss://eth-rinkeby.ws.alchemyapi.io/v2/twReQE9Px03E-E_N_Fbb3OVF7YgHxoGq'],
    explorer: 'https://rinkeby.etherscan.io',
  },
  [ChainId.ETH_GOERLI]: {
    key: '5',
    name: 'Ethereum Testnet Görli',
    shortName: 'Görli',
    chainId: 5,
    network: 'goerli',
    rpc: ['https://eth-goerli.alchemyapi.io/v2/gzMLSQ6EPmP6ZsTd0BOCQ7tPwAmgWpO9'],
    explorer: 'https://goerli.etherscan.io',
  },
  [ChainId.ETH_KOVAN]: {
    key: '42',
    name: 'Ethereum Testnet Kovan',
    shortName: 'Kovan',
    chainId: 42,
    network: 'kovan',
    rpc: [
      'https://eth-kovan.alchemyapi.io/v2/QCsM2iU0bQ49eGDmZ7-Y--Wpu0lVWXSO',
      'https://poa-kovan.gateway.pokt.network/v1/5f76124fb90218002e9ce985',
    ],
    ws: ['wss://eth-kovan.ws.alchemyapi.io/v2/QCsM2iU0bQ49eGDmZ7-Y--Wpu0lVWXSO'],
    explorer: 'https://kovan.etherscan.io',
  },
  [ChainId.BSC_MAINNET]: {
    key: '56',
    name: 'BNB Chain Mainnet',
    shortName: 'BNB Chain',
    chainId: 56,
    network: 'mainnet',
    rpc: [
      'https://bsc-private-dataseed1.nariox.org',
      'https://bsc-private-dataseed2.nariox.org',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
    ],
    explorer: 'https://bscscan.com',
  },
  [ChainId.BSC_TESTNET]: {
    key: '97',
    name: 'BNB Chain Testnet',
    shortName: 'BNB Chain Testnet',
    chainId: 97,
    network: 'testnet',
    rpc: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
    explorer: 'https://testnet.bscscan.com',
  },
  [ChainId.MILKOMEDA_CARDANO_TESTNET]: {
    key: '200101',
    name: 'Milkomeda Cardano C1',
    shortName: 'Milkomeda Testnet',
    chainId: 200101,
    network: 'testnet',
    rpc: ['https://use-util.cloud.milkomeda.com:8555'],
    explorer: 'https://explorer-devnet-cardano-evm.c1.milkomeda.com',
    nativeCurrency: {
      name: 'MilkADA',
      symbol: 'MTADA',
      decimals: 18,
    },
    iconLink: '/images/tokens/cardano.svg',
  },
  [ChainId.MILKOMEDA_CARDANO_MAINNET]: {
    key: '2001',
    name: 'Milkomeda Cardano C1',
    shortName: 'Milkomeda',
    chainId: 2001,
    network: 'mainnet',
    rpc: ['https://rpc-mainnet-cardano-evm.c1.milkomeda.com'],
    explorer: 'https://explorer-mainnet-cardano-evm.c1.milkomeda.com',
    nativeCurrency: {
      name: 'MilkADA',
      symbol: 'mADA',
      decimals: 18,
    },
    iconLink: '/images/tokens/cardano.svg',
  },
  [ChainId.MILKOMEDA_ALGORAND_TESTNET]: {
    key: '200202',
    name: 'Milkomeda Algorand A1',
    shortName: 'Milkomeda Algorand Testnet',
    chainId: 200202,
    network: 'testnet',
    rpc: ['https://rpc-devnet-algorand-rollup.a1.milkomeda.com'],
    explorer: 'https://testnet-algorand-rollup.a1.milkomeda.com',
    nativeCurrency: {
      name: 'MilkALGO',
      symbol: 'mALGO',
      decimals: 18,
    },
    iconLink: '/images/tokens/algorand.svg',
  },
  [ChainId.MILKOMEDA_ALGORAND_MAINNET]: {
    key: '2002',
    name: 'Milkomeda Algorand A1',
    shortName: 'Milkomeda A1 Mainnet',
    chainId: 2002,
    network: 'mainnet',
    rpc: ['https://rpc-mainnet-algorand-rollup.a1.milkomeda.com'],
    explorer: 'https://explorer-mainnet-algorand-rollup.a1.milkomeda.com',
    nativeCurrency: {
      name: 'milkALGO',
      symbol: 'mALGO',
      decimals: 18,
    },
    iconLink: '/images/tokens/algorand.svg',
  },
  [ChainId.MILKOMEDA_KAVA_TESTNET]: {
    key: '2221',
    name: 'Kava',
    shortName: 'Kava Testnet',
    chainId: 2221,
    network: 'testnet',
    rpc: ['https://evm.testnet.kava.io'],
    explorer: 'https://explorer.testnet.kava.io',
    nativeCurrency: {
      name: 'KAVA',
      symbol: 'KAVA',
      decimals: 18,
    },
    iconLink: '/images/tokens/kava.svg',
  },
  [ChainId.MILKOMEDA_KAVA_MAINNET]: {
    key: '2222',
    name: 'Kava',
    shortName: 'Kava Mainnet',
    chainId: 2222,
    network: 'mainnet',
    rpc: ['https://evm.kava.io'],
    explorer: 'https://explorer.kava.io',
    nativeCurrency: {
      name: 'KAVA',
      symbol: 'KAVA',
      decimals: 18,
    },
    iconLink: '/images/tokens/kava.svg',
  },
  [ChainId.MILKOMEDA_POLYGON_TESTNET]: {
    key: `${ChainId.MILKOMEDA_POLYGON_TESTNET}`,
    name: 'Polygon',
    shortName: 'Polygon Testnet',
    chainId: ChainId.MILKOMEDA_POLYGON_TESTNET,
    network: 'testnet',
    rpc: [`${process.env['VUE_APP_POLYGON_ENGINE_NETWORK']}`],
    explorer: BASE_SCAN_URLS[ChainId.MILKOMEDA_POLYGON_TESTNET],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    iconLink: '/images/tokens/matic.svg',
  },
  [ChainId.MILKOMEDA_POLYGON_MAINNET]: {
    key: `${ChainId.MILKOMEDA_POLYGON_MAINNET}`,
    name: 'Polygon',
    shortName: 'Polygon',
    chainId: ChainId.MILKOMEDA_POLYGON_MAINNET,
    network: 'mainnet',
    rpc: [`${process.env['VUE_APP_POLYGON_ENGINE_NETWORK']}`],
    explorer: BASE_SCAN_URLS[ChainId.MILKOMEDA_POLYGON_MAINNET],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    iconLink: '/images/tokens/matic.svg',
  },
  [ChainId.BLUES_CHAIN_TESTNET]: {
    key: `${ChainId.BLUES_CHAIN_TESTNET}`,
    name: 'Blueschain testnet',
    shortName: 'Blueschain testnet',
    chainId: ChainId.BLUES_CHAIN_TESTNET,
    network: 'testnet',
    explorer: BASE_SCAN_URLS[ChainId.BLUES_CHAIN_TESTNET],
    iconLink: '/images/tokens/blues.svg',
  },
  [ChainId.BLUES_CHAIN_MAINNET]: {
    key: `${ChainId.BLUES_CHAIN_MAINNET}`,
    name: 'Blueschain',
    shortName: 'Blueschain',
    chainId: ChainId.BLUES_CHAIN_MAINNET,
    network: 'mainnet',
    explorer: BASE_SCAN_URLS[ChainId.BLUES_CHAIN_MAINNET],
    iconLink: '/images/tokens/blues.svg',
  },
  [ChainId.MILKOMEDA_NEON_TESTNET]: {
    key: `${ChainId.MILKOMEDA_NEON_TESTNET}`,
    name: 'Neon EVM',
    shortName: 'Neon EVM DevNet',
    chainId: ChainId.MILKOMEDA_NEON_TESTNET,
    network: 'testnet',
    rpc: [`${process.env['VUE_APP_NEON_ENGINE_NETWORK']}`],
    explorer: BASE_SCAN_URLS[ChainId.MILKOMEDA_NEON_TESTNET],
    nativeCurrency: {
      name: 'NEON',
      symbol: 'NEON',
      decimals: 18,
    },
    iconLink: '/images/tokens/neon.svg',
  },
};
