import pkg from '@/../package.json';
import { JsonRpcProvider } from '@ethersproject/providers';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { Ref } from '@vue/reactivity';
import BigNumber from 'bignumber.js';
import { parseUnits } from '@ethersproject/units';
import { NETWORK_ENGINE } from '@/helpers/networkParams.helper';

export function jsonParse(input, fallback?) {
  if (typeof input !== 'string') {
    return fallback || {};
  }
  try {
    return JSON.parse(input);
  } catch (err) {
    return fallback || {};
  }
}

export async function sleep(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

export function clone(item) {
  return JSON.parse(JSON.stringify(item));
}

export function lsSet(key: string, value: any) {
  return localStorage.setItem(`${pkg.name}.${key}`, JSON.stringify(value));
}

export function lsGet(key: string, fallback?: any) {
  const item = localStorage.getItem(`${pkg.name}.${key}`);
  return jsonParse(item, fallback);
}

export function lsRemove(key: string) {
  return localStorage.removeItem(`${pkg.name}.${key}`);
}

export function getRPCProvider() {
  const auth = getInstance();
  console.log(auth.web3);
  return auth.web3;
}

export function dateNowInSeconds() {
  return Math.floor(Date.now() / 1000);
}

export function dateNowPlusEstimatedSeconds(time = 3600) {
  return dateNowInSeconds() + time;
}

export function safeDateNowPlusEstimatedMinutes(minutes = 0) {
  let seconds = minutes * 60;
  const safeMinimumSecondsInterval = 60;
  if (seconds < safeMinimumSecondsInterval) {
    seconds = safeMinimumSecondsInterval;
  }
  return dateNowInSeconds() + Math.floor(seconds);
}

export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function getDefaultProvider() {
  return new JsonRpcProvider(NETWORK_ENGINE);
}

export function safeParseUnits(amount: string | null, decimals = 18): string {
  const preparedAmount = new BigNumber(amount || 0).toFixed(decimals);
  return parseUnits(preparedAmount.valueOf(), decimals).toString();
}

export function debounce(f: (...args) => void, ms: number) {
  let timer;

  return function (this: unknown, ...args) {
    if (timer) {
      clearTimeout(timer);
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const context = this;
    timer = setTimeout(() => {
      f.apply(context, args);
    }, ms);
  };
}

export type MayBeRef<T> = T | Ref<T>;
