import { computed, ComputedRef, ref, watch } from 'vue';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { useStore } from 'vuex';
import { defineStore } from 'pinia';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { UPDATE_INTERVAL } from '@/helpers/constants';
import { BN_ZERO, ChainId, ethersToJSBI } from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Token } from '@/sdk/entities/token';
import multicall, { Call } from '@/utils/multicall';
import erc20 from '@/data/abi/erc20.json';
import { DEFAULT_NETWORK_ID } from '@/helpers/networkParams.helper';
import { BIG_ZERO } from '@/utils/bigNumber';
import { useTokens } from './useTokens';

export enum FetchStatus {
  NOT_FETCHED = 'not-fetched',
  SUCCESS = 'success',
  FAILED = 'failed',
}

type TokenBalance = {
  balance: TokenAmount;
  status: FetchStatus;
};

export const useBalances = defineStore('balances', () => {
  const { state } = useStore();
  const { getTokensListByChainId } = useTokens();

  const balances = ref<Record<string, TokenBalance> | null>();

  const getTokensForCurrentChainId = (): Token[] => {
    if (!DEFAULT_NETWORK_ID) return [];
    return getTokensListByChainId(DEFAULT_NETWORK_ID as unknown as ChainId);
  };

  const tokenBalanceWei = (symbol: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balance = balances.value?.[symbol];

      if (!balance) {
        return BIG_ZERO;
      }

      return new BigNumber(balance.balance.raw.toString());
    });
  };

  const tokenBalanceRelative = (symbol: string): ComputedRef<BigNumber> => {
    return computed(() => {
      const balance = balances.value?.[symbol];

      if (!balance) {
        return BIG_ZERO;
      }

      return new BigNumber(balance.balance.toExact());
    });
  };

  // TODO: getter from token module
  const getBalanceByToken = (token: Token): TokenBalance | null => {
    const balance = token.symbol ? balances.value?.[token.symbol] : null;
    if (!balance) return null;

    return balance;
  };

  // TODO: getter from token module
  const getBalancesWithFilter = (
    filter?: Token,
  ): Record<string, TokenBalance> | TokenBalance | null => {
    if (!balances.value || !Object.keys(balances.value).length) return null;
    if (filter && filter.symbol) return balances.value[filter.symbol];
    return balances.value;
  };

  const balanceByToken = (token: Token): ComputedRef<TokenBalance | null> => {
    return computed(() => {
      return getBalanceByToken(token);
    });
  };

  const initTokenBalances = () => {
    const balancesList = {};
    getTokensForCurrentChainId().forEach(token => {
      const tokenBalance = {
        balance: new TokenAmount(token, BN_ZERO),
        status: FetchStatus.NOT_FETCHED,
      };
      balancesList[token.symbol!] = tokenBalance;
    });

    balances.value = balancesList;
  };

  const updateTokenBalances = async (): Promise<void> => {
    if (!state || !state.wallet || !state.wallet.isInjected || !state.wallet.isNetworkSupported) {
      return;
    }

    const tokens: Token[] = [];
    const gasToken: Token[] = [];

    const callsAccountBalances: Call[] = [];
    getTokensForCurrentChainId().forEach(token => {
      if (!token.description?.isPresentLocally) return;

      if (token.isETHToken()) {
        gasToken.push(token);
        return;
      }

      tokens.push(token);
      callsAccountBalances.push({
        address: token.address,
        name: 'balanceOf',
        params: [state.wallet.account],
      });
    });

    const balancesList = balances.value || {};
    try {
      const accountBalances = multicall<ethers.BigNumber[][]>(erc20, callsAccountBalances);
      const baseTokenBalance: Promise<ethers.BigNumber> = getInstance()
        .web3.getSigner()
        .getBalance('latest');
      const balancesResponse = await Promise.all([accountBalances, baseTokenBalance]);
      const requestedTokens = [...tokens, ...gasToken];

      [...balancesResponse[0], balancesResponse[1]].forEach((rawBalance, index) => {
        const token = requestedTokens[index];
        const tokenBalance = {
          balance: new TokenAmount(token, ethersToJSBI(rawBalance[0] ?? rawBalance)),
          status: FetchStatus.SUCCESS,
        };
        balancesList[token.symbol!] = tokenBalance;
      });
    } catch (ex) {
      console.error('[UPDATE:TOKENS:ERROR] Error: ', ex);

      Object.entries(balancesList).forEach(([key, balance]) => {
        balancesList[key] = {
          balance: balance.balance,
          status: FetchStatus.FAILED,
        };
      });
    }
    balances.value = balancesList;
    console.log('BALANCES: ', balances.value);
  };

  const updateGasTokenBalance = async (): Promise<void> => {
    if (!state || !state.wallet || !state.wallet.isInjected || !state.wallet.isNetworkSupported) {
      return;
    }

    let gasToken: Token | undefined;
    const tokens = getTokensForCurrentChainId();

    for (let index = 0; index < tokens.length; index++) {
      const token = tokens[index];
      if (token.isETHToken()) {
        gasToken = token;
        return;
      }
    }

    if (!gasToken) {
      console.warn('[UPDATE:GAS:TOKEN:BALANCE] Can not find gas token in list of tokens.');
      return;
    }

    const balancesList = balances.value || {};
    try {
      const baseTokenBalance: ethers.BigNumber = await getInstance()
        .web3.getSigner()
        .getBalance('latest');

      const tokenBalance = {
        balance: new TokenAmount(gasToken, ethersToJSBI(baseTokenBalance)),
        status: FetchStatus.SUCCESS,
      };
      balancesList[gasToken.symbol!] = tokenBalance;
    } catch (ex) {
      console.error('[UPDATE:GAS:TOKEN:ERROR] Error: ', ex);

      const tokenBalance = {
        balance: new TokenAmount(gasToken, BN_ZERO),
        status: FetchStatus.FAILED,
      };
      balancesList[gasToken.symbol!] = tokenBalance;
    }
    balances.value = balancesList;
    console.log('BALANCES: ', balances.value);
  };

  watch(
    () => state.wallet.account,
    account => {
      if (account) {
        updateTokenBalances();
      }
    },
    { immediate: true },
  );

  setInterval(updateTokenBalances, UPDATE_INTERVAL);

  return {
    balances,
    getBalancesWithFilter,
    getBalanceByToken,
    balanceByToken,
    tokenBalanceWei,
    tokenBalanceRelative,
    initTokenBalances,
    updateTokenBalances,
    updateGasTokenBalance,
  };
});
