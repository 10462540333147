// see https://stackoverflow.com/a/41102306
const CAN_SET_PROTOTYPE = 'setPrototypeOf' in Object;

/**
 * Indicates that some app error happened
 */
export class BlueshiftError extends Error {
  translationKey: string;
  payload: any;

  public constructor(translationKey: string, payload: any) {
    super();
    this.name = this.constructor.name;
    this.translationKey = translationKey;
    this.payload = payload;
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Indicates that the pair has insufficient reserves for a desired output amount. I.e. the amount of output cannot be
 * obtained by sending any amount of input.
 */
export class InsufficientReservesError extends Error {
  public readonly isInsufficientReservesError: true = true;

  public constructor() {
    super();
    this.name = this.constructor.name;
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Indicates that the input amount is too small to produce any amount of output. I.e. the amount of input sent is less
 * than the price of a single unit of output after fees.
 */
export class InsufficientInputAmountError extends Error {
  public readonly isInsufficientInputAmountError: true = true;

  public constructor() {
    super();
    this.name = this.constructor.name;
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Indicates that the error happened while bridging. I.e. the entered amount is less
 * than the minimum allowed in Milkomeda.
 */
export class BridgeError extends BlueshiftError {
  public constructor(translationKey: string, payload: any) {
    super(translationKey, payload);
    this.name = 'BridgeError';
  }
}

/**
 * Indicates that the input amount exceeds the balance
 */
export class BalanceError extends BridgeError {
  public constructor(translationKey: string, payload?: any) {
    super(translationKey, payload || {});
  }
}

/**
 * Indicates that the entered amount is less than the allowed minimum.
 */
export class MinAmountError extends BridgeError {
  minAmount: string | undefined;
  tokenSymbol: string | undefined;

  public constructor(
    translationKey: string,
    payload: { minAmount: string | undefined; tokenSymbol: string | undefined },
  ) {
    super(translationKey, payload);
    this.minAmount = payload.minAmount;
    this.tokenSymbol = payload.tokenSymbol;
  }
}

/**
 * Indicates that the entered amount is greater than the allowed maximum.
 */
export class MaxAmountError extends BridgeError {
  maxAmount: string | undefined;
  tokenSymbol: string | undefined;

  public constructor(
    translationKey: string,
    payload: { maxAmount: string | undefined; tokenSymbol: string | undefined },
  ) {
    super(translationKey, payload);
    this.maxAmount = payload.maxAmount;
    this.tokenSymbol = payload.tokenSymbol;
  }
}

/**
 * Indicates that there is a problem with Cardano wallet.
 * I.e. Cardano wallet was not initialized before calculationg bridge swap
 */
export class CardanoWalletError extends BridgeError {
  public constructor(translationKey: string, payload?: any) {
    super(translationKey, payload || {});
  }
}

/**
 * Indicates that there is a problem with Milkomeda wallet.
 * I.e. Milkomeda wallet address is 0x00
 */
export class MilkomedaWalletError extends BridgeError {
  public constructor(translationKey: string, payload?: any) {
    super(translationKey, payload || {});
  }
}

/**
 * Indicates that there is not enough Ada for the NonAda transaction.
 */
export class CurrencyForFeeError extends BridgeError {
  public constructor(translationKey: string, payload: any) {
    super(translationKey, payload);
  }
}

/**
 * Indicates that some error happened with wallet
 */
export class WalletError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    if (CAN_SET_PROTOTYPE) Object.setPrototypeOf(this, new.target.prototype);
  }
}

/**
 * Indicates that the wallet is not installed
 */
export class WalletIsNotInstalledError extends WalletError {
  public constructor() {
    super();
  }
}
