import { useLocalStorage } from '@/composables/useLocalStorage';
import { BASE_TOKENS } from '@/constants/baseTokens';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import liquifiTokens from '@/data/lists/liquifi.tokenlist.json';
import { NATIVE_TOKEN_SYMBOL } from '@/helpers/constants';
import {
  DEFAULT_NETWORK_NAME,
  NETWORKS_SUPPORTING_BRIDGE,
  NETWORKS_SUPPORTING_FARMS,
  NETWORKS_SUPPORTING_YIELD_POOLS,
  NETWORK_OPTIONS,
} from '@/constants/NETWORK_PARAMS';
import networks from '@/helpers/networks';
import { getURLParam } from '@/helpers/appParams.helper';
import { capitalizeFirstLetter } from '@/helpers/utils';
import { AddEthereumChainParameter } from '@/utils/blockchain';
import { ChainId } from '@/sdk/constants';

export const getNetworkId = netName => {
  return process.env['VUE_APP_' + netName.toUpperCase() + '_NETWORK_ID'];
};

export const getNetworkParams = (netId): AddEthereumChainParameter => {
  const net = networks[netId];
  const chainId = +net.key;
  const chainIdHec = '0x' + chainId.toString(16);

  return {
    chainId: chainIdHec,
    rpcUrls: net.rpc,
    chainName: net.shortName,
    nativeCurrency: net.nativeCurrency,
    blockExplorerUrls: [net.explorer],
    iconUrls: [net.iconLink],
  };
};

export const getNetworkURLParam = () => {
  const supportedNetOptions = NETWORK_OPTIONS[SUPPORTED_NETWORK_MODE.toLowerCase()];
  const supportedNetsShortNames = supportedNetOptions.map(item => item.value);
  const netURLParam = capitalizeFirstLetter(getURLParam('network'));
  const isURLNetSupported = supportedNetsShortNames.includes(netURLParam);

  return isURLNetSupported ? netURLParam : '';
};

export const SELECTED_NETWORK_NAME = String(
  getNetworkURLParam() ||
    useLocalStorage().getItem(`network_${SUPPORTED_NETWORK_MODE}`) ||
    DEFAULT_NETWORK_NAME,
);

export const CROSS_SITE_NETWORK_PREFIXES = {};
NETWORK_OPTIONS[SUPPORTED_NETWORK_MODE.toLowerCase()].forEach(option => {
  CROSS_SITE_NETWORK_PREFIXES[option.value.toUpperCase()] = option.crossSite.prefix;
});

export const DEFAULT_NETWORK_ID = getNetworkId(SELECTED_NETWORK_NAME);

export const FULL_SELECTED_NETWORK_NAME = networks[Number(DEFAULT_NETWORK_ID)].name;

export const SUPPORTED_NETWORK_IDS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_SUPPORTED_NETWORK_IDS'];

export const NETWORK_BACKEND_URL =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_BACKEND_URL'];

export const NETWORK_ENGINE =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_ENGINE_NETWORK'];

export const NETWORK_USD_TOKEN_ADDRESS =
  process.env['VUE_APP_' + SELECTED_NETWORK_NAME.toUpperCase() + '_USD_TOKEN_ADDRESS'];

export const NETWORK_ETH_TOKEN_NAME = BASE_TOKENS[String(DEFAULT_NETWORK_ID)].symbol;

export const NETWORK_ETH_TOKEN_ADDRESS = BASE_TOKENS[String(DEFAULT_NETWORK_ID)].address;

export const NETWORK_WETH_TOKEN_NAME = liquifiTokens.tokens
  .filter(token => token.address == NETWORK_ETH_TOKEN_ADDRESS)
  .map(token => token.symbol)[0];

export const CURRENT_NATIVE_TOKEN_ADDRESS = liquifiTokens.tokens
  .filter(
    token => token.symbol == NATIVE_TOKEN_SYMBOL && String(token.chainId) == DEFAULT_NETWORK_ID,
  )
  .map(token => token.address)[0];

export const NETWORK_SUPPORTS_BRIDGE = NETWORKS_SUPPORTING_BRIDGE.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_FARMS = NETWORKS_SUPPORTING_FARMS.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const NETWORK_SUPPORTS_YIELD_POOLS = NETWORKS_SUPPORTING_YIELD_POOLS.includes(
  Number(DEFAULT_NETWORK_ID),
);

export const BLUES_CROSSCHAIN_URL = process.env.VUE_APP_BLUES_CROSSCHAIN_URL;

export const BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC =
  Number(process.env.VUE_APP_BLUES_CROSSCHAIN_TRANSACTION_STATUS_RETRY_DELAY_IN_SEC) || 5;

export const ANALYTIC_BACKEND_URL = process.env.VUE_APP_ANALYTIC_BACKEND_URL;

export const getChainNameById = (id: ChainId): string => {
  const chainName = networks[id]?.name;
  if (!chainName) {
    throw new Error(`Blockchain ${id} was not found.`);
  }
  return chainName;
};
