import BigNumber from 'bignumber.js';

import { BIG_ZERO, ethersToBigNumber } from '@/utils/bigNumber';
import { Portfolio } from './portfolio';
import { FarmSourceType } from './farmSource';
import { BigintIsh, BLOCKS_PER_YEAR, BOT_USD_DECIMALS, DEFAULT_TOKEN_DECIMAL } from '../constants';
import { TokenAmount } from './fractions/tokenAmount';
import { Token } from './token';
import { useReferral } from '@/store/modules/referral/useReferral';
import { APR_INCREASE_PERCENT } from '@/constants/REFERRAL_PARAMS';
import { LP_TOKEN_DECIMALS } from './constants/LP_TOKEN_DECIMALS';
import { fromWei } from '../utils';

export class PortfolioFarm {
  public readonly multiplier: BigNumber;
  public readonly rewardPerBlock: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly deposited: BigNumber;
  public readonly earned: BigNumber;
  public readonly totalReward: BigNumber;
  public readonly portfolio: Portfolio;
  public readonly nativeTokenPriceInBase: BigNumber;
  public readonly priceInUSD: BigNumber;
  public readonly liquidityToken: Token;
  public readonly fee: BigNumber;
  public readonly tokensOwned: TokenAmount;
  public totalSupply: BigNumber;
  public readonly token: string;
  public readonly farm: string;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    statusFarms: object,
    portfolio: Portfolio,
    nativeTokenPriceInBase: BigNumber,
    fee: string,
    tokensOwned: BigintIsh,
    totalSupply: BigNumber,
    priceInUSD: BigNumber,
  ) {
    console.log('PortfolioFarm: statusFarms', statusFarms);
    this.multiplier = ethersToBigNumber(statusFarms['multiplier']);
    this.rewardPerBlock = ethersToBigNumber(statusFarms['rewardPerBlock']);
    this.accDeposited = ethersToBigNumber(statusFarms['accDeposited']);
    this.deposited = ethersToBigNumber(statusFarms['deposited']);
    this.earned = ethersToBigNumber(statusFarms['earned']);
    this.totalReward = ethersToBigNumber(statusFarms['totalReward']);
    this.portfolio = portfolio;
    this.token = statusFarms['token'];
    this.farm = statusFarms['farm'];
    this.nativeTokenPriceInBase = nativeTokenPriceInBase; // in USD
    this.priceInUSD = priceInUSD;
    console.log('priceInUsd', this.portfolio.lpTokenAddress, this.priceInUSD.toString());
    this.liquidityToken = new Token(
      this.portfolio.tokens[0].token.chainId,
      this.portfolio.lpTokenAddress,
      LP_TOKEN_DECIMALS,
      'LQF-V2',
      'Liquifi V2',
    );
    this.tokensOwned = new TokenAmount(this.liquidityToken, tokensOwned);
    this.totalSupply = totalSupply;
    this.fee = new BigNumber(fee);
  }

  public get sourceType(): FarmSourceType {
    return FarmSourceType.PORTFOLIO;
  }

  public get printDeposited(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.deposited.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printDepositedUSD(): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.deposited);
    const farmTotalValueBase = new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
    return this.getValueInUSD(farmTotalValueBase.toExact());
  }

  public get printLiquidity(): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.accDeposited);
    console.log(
      `[LIQUIDITY | ${this.portfolio.name}] Liquidity in ${this.portfolio.baseToken.symbol} (LP decimals) :`,
      lpPrice.toString(),
    );
    const farmTotalValueBase = new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
    return this.getValueInUSD(farmTotalValueBase.toExact());
  }

  public get printTotalReward(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.totalReward.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarned(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarnedUSD(): TokenAmount {
    const earned = this.earned.multipliedBy(this.nativeTokenPriceInBase);
    return new TokenAmount(
      this.liquidityToken,
      earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printMultiplier(): string {
    return this.multiplier.toFixed();
  }

  public get printIPR(): number {
    const rewardPerBlock = this.rewardPerBlock.dividedBy(DEFAULT_TOKEN_DECIMAL);
    const fee = fromWei(this.fee, BOT_USD_DECIMALS);
    const accDeposited = fromWei(this.accDeposited, LP_TOKEN_DECIMALS);
    const aprLeft = rewardPerBlock
      .multipliedBy(this.nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR))
      .plus(fee.multipliedBy(365).dividedBy(30));
    const aprRight = this.getValueInUSD(this.lpTokenPrice().toString()).multipliedBy(accDeposited);
    const { referralState } = useReferral();

    let ipr = aprLeft
      .dividedBy(aprRight)
      .multipliedBy(100)
      .integerValue(BigNumber.ROUND_DOWN)
      .toNumber();
    ipr = referralState.isReferral ? ipr * (1 + APR_INCREASE_PERCENT.REFERRAL / 100) : ipr;

    console.groupCollapsed(`[${this.portfolio.name}] IPR : `, ipr.toString());
    console.log('rewardPerBlock', rewardPerBlock.toString());
    console.log('nativeTokenPriceInBase', this.nativeTokenPriceInBase.toString());
    console.log('BLOCKS_PER_YEAR', BLOCKS_PER_YEAR);
    console.log(
      'nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR)',
      this.nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR).toString(),
    );
    console.log('fee', this.fee.toString(), fee.toString());
    console.log('aprLeft', aprLeft.toString());
    console.log('lpTokenPrice', this.lpTokenPrice().toString());
    console.log('priceInUSD', this.priceInUSD.toString());
    console.log(
      'lpTokenPrice in USD',
      this.getValueInUSD(this.lpTokenPrice().toString()).toString(),
    );
    console.log('accDeposited', this.accDeposited.toString(), accDeposited.toString());
    console.log('aprRight', aprRight.toString());
    console.groupEnd();

    return ipr !== Infinity && !isNaN(ipr) ? ipr : 0;
  }

  // price: LP token in portfolio base token (from WEI)
  public lpTokenPrice(): BigNumber {
    const lpTP = this.portfolio.lpTokenPriceBase;
    return !lpTP.isNaN() ? lpTP : BIG_ZERO;
  }

  public calculateUSD(value: string): BigNumber {
    const lpPrice = this.lpTokenPrice().multipliedBy(value || BIG_ZERO);
    return this.getValueInUSD(lpPrice.toString());
  }

  public getValueInUSD(value: string, priceValue?: BigNumber): BigNumber {
    const valueInUSD = this.priceInUSD.multipliedBy(value);
    if (priceValue) {
      return valueInUSD.multipliedBy(priceValue);
    }
    return valueInUSD;
  }
}
