import { ChainId } from '@/sdk/constants';

export default {
  poolFactory: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x6455b3FE3cB9815d71D296f0CFFEfc1d591A91f3',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x6404d59Cd95652bE91607D66B9e57853E2EbF9A1',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x0000000000000000000000000000000000000000',
  },
  router: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xa9803EbdF322351b88d1227dA95F245aDE731ae7',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x0cc83233CFe0e4A40797d59CE71656163B24f871',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0xc20130B3f9d2D588894161A8467EdD5f297A019F',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x4f9F1bCB61faE5ABf513Da8Db204aF594AB6Fbb9',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0x24a3949cd863B8F84c38372789cD80Cdc80245E1',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0x9c972b2f9528CeC7a1C53a866c9951205bb71CEe',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0x515F2F3679Af75Cf5b2971B5Db3c73B09E1D3DcB',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0xB470e97Ef18720Ec42F4a855812a51D9aCADF2C4',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x49c26558df84A0B4BFF8dB8B605C8241792883Bf',
  },
  minter: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xdE6AB15d0786a0034B28Ed7e6B21ed95099CF48B',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0xC251D9F869D178Ce09E08fa830b35C0E850344F5',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x6455b3FE3cB9815d71D296f0CFFEfc1d591A91f3',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x18390a3db65e07AAC892DB1616C5b1d268FA0beF',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0xd65080B5D7686fA7D46Bd19cBEDB6B03FEd37e8c',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0x39F220f50f67BC6d7CeD35affba82191c43668b5',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0xd585A1Be0db5e93A09fA090eECDA695dDe8ce25A',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x83E5F924ad457B05dC6bED5D5Ff6F7f5Ef11e475',
  },
  liquifiV2PortfolioAndPairRegistry: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xfc9d755235EAc69E5DC72B3eaFbBeee45D5a5a68',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0xcFBAC83270d0822beC0410381C5c0973686d7B0a',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x969133274BF63A48fBa9eBDc9AE1A3bE3c4a8e7b',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x94448BaAb0ef7d737f169BAfFb580795A9f1d909',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0xE653c7FF45BB340a40Dd81Bb174C5714113deAbe',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0xB54c6315AD8c19b5603BA49D35368367D07689c9',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0xB6B3b594e707072AbD475A4c8a48235ECa13C9Cb',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0xDd036487062d426A4dA67D342c755e4De178bFEB',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x654D7e84e8c8888AaAa82c7728aaC2765F1b7BD2',
  },
  multiCall: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0xa46157Cda2D019Ba4cDcd8cE12A04760c15C355b',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x0042c23B7A286cd0ed96C9FA31282057a061DB68',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0x61EEE5a6c13c358101487f3b7c7Dd9863590C350',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0x61EEE5a6c13c358101487f3b7c7Dd9863590C350',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0xfd5843DEa7209747df09767D0A2b9A6173e13901',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0xB16eddC4855B2A0D3482c83E0D1367b6137a3F4D',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0xE518b5EBCFf61B2D6768A089515caeE47273c13b',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0xF991039AB3590325629B5b09f9Ea35F4550ce01C',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x72C2A39401e6039B8Af220521B8FcdFCD3479553',
  },
  autoPool: {
    [ChainId.MILKOMEDA_CARDANO_MAINNET]: '0x8F141ed07b53D5483ed30b3E688F388fE3B412AF',
    [ChainId.MILKOMEDA_CARDANO_TESTNET]: '0x8A024135d2B406B3DD13cCE2CaBa66E9bd8D3692',
    [ChainId.MILKOMEDA_ALGORAND_MAINNET]: '0xE0dFBce50f8266aA8A488dB4A5f2B40DD1f0FaeA',
    [ChainId.MILKOMEDA_ALGORAND_TESTNET]: '0xC2F160700148Ac7ada3Df86ac1c6174B53c066C6',
    [ChainId.MILKOMEDA_KAVA_TESTNET]: '0x3A10930072519755027A99f82DB278f4E0FBe8CB',
    [ChainId.MILKOMEDA_KAVA_MAINNET]: '0x8d7A76CB2cE26136b86f311e3422E939493608c9',
    [ChainId.MILKOMEDA_POLYGON_TESTNET]: '0x26e7fC8cD03A00CCF08303F3A01b92aE462A2754',
    [ChainId.MILKOMEDA_POLYGON_MAINNET]: '0x0000000000000000000000000000000000000000',
    [ChainId.MILKOMEDA_NEON_TESTNET]: '0x07f8c8796df7E63c204cb543B455D2127C9548B3',
  },
};
