import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { JsonRpcProvider, Web3Provider } from '@ethersproject/providers';
import connectors from '@/helpers/connectors.json';
import networks from '@/helpers/networks';
import { getUrlWithoutParam, updatePageUrl } from '@/helpers/appParams.helper';
import { isNetworkSupported, SUPPORTED_NETWORK_IDS } from '@/utils/blockchain';
import { reloadPage } from '@/utils/page';
import { WalletError, WalletIsNotInstalledError } from '@/sdk/errors';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { SUPPORTED_NETWORK_MODE } from '@/constants/SUPPORTED_NETWORK_MODE';
import {
  NETWORK_ENGINE,
  SELECTED_NETWORK_NAME,
  getNetworkParams,
} from '@/helpers/networkParams.helper';

let auth;

export const WALLET_ACTION_TYPES = {
  WALLET_LOGIN: 'walletLogin',
  WALLET_LOGOUT: 'walletLogout',
  INIT_WALLET: 'initWallet',
  LOAD_PROVIDER: 'loadProvider',
  INIT_WEB_3_ENGINE: 'initWeb3Engine',
};

export const WALLET_MUTATION_TYPES = {
  SET_STATE: 'setState',
};

export const state = {
  authLoading: false,
  isInjected: false,
  web3: null,
  web3engine: null,
  network: null,
  selectedNetworkName: '',
  account: '0x0000000000000000000000000000000000000000',
  connector: null,
  isNetworkSupported: null,
  walletConnectType: null,
};

export const getters = {
  getWalletName: state => {
    return state.account || null;
  },
  getWalletNetwork: state => {
    return networks[state.network?.chainId] || null;
  },
  isInjected: state => {
    return state.isInjected;
  },
  getSupportedNetworksNames() {
    const suppotredNetworks = SUPPORTED_NETWORK_IDS.map(networkId => {
      return networks[networkId]?.name || 'not defined';
    });
    return suppotredNetworks.join(', ');
  },
  getFirstSupportedNetwork() {
    return getNetworkParams(SUPPORTED_NETWORK_IDS[0]);
  },
};
export const actions = {
  async [WALLET_ACTION_TYPES.INIT_WEB_3_ENGINE]({ commit }) {
    const pr = await new JsonRpcProvider(NETWORK_ENGINE);
    commit(WALLET_MUTATION_TYPES.SET_STATE, {
      web3engine: pr,
    });
  },
  async [WALLET_ACTION_TYPES.INIT_WALLET]({ dispatch, commit }) {
    useLocalStorage().setItem(`network_${SUPPORTED_NETWORK_MODE}`, SELECTED_NETWORK_NAME);
    commit(WALLET_MUTATION_TYPES.SET_STATE, { selectedNetworkName: SELECTED_NETWORK_NAME });

    auth = getInstance();

    const connector = await auth.getConnector();
    console.log('[INIT_WALLET] connector : ', connector);
    if (connector) {
      await dispatch(WALLET_ACTION_TYPES.WALLET_LOGIN, connector);
    }
  },
  async [WALLET_ACTION_TYPES.WALLET_LOGIN]({ commit, dispatch }, connector = 'injected') {
    auth = getInstance();
    commit(WALLET_MUTATION_TYPES.SET_STATE, { authLoading: true });
    await auth.login(connector);
    if (auth.provider.value) {
      auth.web3 = new Web3Provider(auth.provider.value);
      commit(WALLET_MUTATION_TYPES.SET_STATE, {
        connector: connectors[connector],
      });
      await dispatch(WALLET_ACTION_TYPES.LOAD_PROVIDER);
    } else if (connector !== 'walletconnect') {
      throw new WalletIsNotInstalledError('Wallet is not installed');
    } else {
      throw new WalletError('Wallet connection error');
    }
    commit(WALLET_MUTATION_TYPES.SET_STATE, { authLoading: false });
  },
  async [WALLET_ACTION_TYPES.LOAD_PROVIDER]({ commit }) {
    try {
      if (auth.provider.value.removeAllListeners && !auth.provider.value.isTorus) {
        auth.provider.value.removeAllListeners();
      }

      console.log('[LOCK] provider : ', auth.provider.value);

      const handleChainChanged = () => {
        updatePageUrl(getUrlWithoutParam('network'));
      };

      // HACK:FIX: fix for Flint Wallet
      if (auth.provider.value.isFlint) {
        console.warn('[FIX:FLINT:WALLET] apply fix `_events`');
        const _events = auth.provider.value._events;
        _events['chainChanged'] = handleChainChanged;
        _events['accountsChanged'] = reloadPage;
      }

      auth.provider.value.on?.('chainChanged', handleChainChanged);
      auth.provider.value.on?.('accountsChanged', reloadPage);

      const [network, accounts] = await Promise.all([
        auth.web3.getNetwork(),
        auth.web3.listAccounts(),
      ]);

      commit(WALLET_MUTATION_TYPES.SET_STATE, {
        isInjected: true,
        web3: auth.web3,
        web3engine: auth.web3,
        network: network,
        account: accounts.length > 0 ? accounts[0] : null,
        walletConnectType: auth.provider.value?.wc?.peerMeta?.name || 'unknown',
        isNetworkSupported: isNetworkSupported(network?.chainId),
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async [WALLET_ACTION_TYPES.WALLET_LOGOUT]({ commit }) {
    try {
      await auth.logout();
      commit(WALLET_MUTATION_TYPES.SET_STATE, {
        isInjected: false,
        web3: null,
        network: null,
        account: null,
      });
      reloadPage();
    } catch (e) {
      console.debug(e);
    }
  },
};

export const mutations = {
  [WALLET_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
