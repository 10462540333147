import { SLIPPAGE_TOLERANCE, TRANSACTION_DEADLINE } from '@/helpers/constants';
import { getErc20Contract, getRouterContract } from '@/helpers/contract.helper';
import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import { getRouterAddress } from '@/helpers/address.helper';
import {
  BN_ZERO,
  ethersToJSBI,
  NO_CONTRACT_ADDRESS,
  SOLIDITY_TYPE_MAXIMA,
  SolidityType,
} from '@/sdk/constants';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { parseUnits } from '@ethersproject/units';
import { safeDateNowPlusEstimatedMinutes } from '@/helpers/utils';
import { Token } from '@/sdk/entities/token';
import BigNumber from 'bignumber.js';
import { Pair } from '@/sdk/entities/pair';

export enum PairFields {
  InputA = 'inputA',
  InputB = 'inputB',
}

function getInverseField(field): string {
  return field === PairFields.InputA ? PairFields.InputB : PairFields.InputA;
}

function getEstimatedField(state) {
  if (state[PairFields.InputA].estimated) return PairFields.InputA;
  if (state[PairFields.InputB].estimated) return PairFields.InputB;
  return null;
}

function checkHasTwoTokens(tokenA, tokenB) {
  return tokenA instanceof Token && tokenB instanceof Token;
}

export const PAIRS_ACTION_TYPES = {
  PAIRS_RESET_STATE: 'pairsResetState',
  PAIRS_SET_TOKEN: 'pairsSetToken',
  PAIRS_SET_AMOUNT: 'pairsSetAmount',
  PAIRS_SET_ESTIMATED: 'pairsSetEstimated',
  PAIRS_VALIDATE: 'pairsValidate',
  PAIRS_SET_PAIR: 'pairsSetPair',
  PAIRS_ADD_LIQUIDITY: 'pairsAddLiquidity',
  PAIRS_ADD_LIQUIDITY_ETH: 'pairsAddLiquidityEth',
  PAIRS_WITHDRAW: 'pairsWithdraw',
  PAIRS_WITHDRAW_ETH: 'pairsWithdrawEth',
  PAIRS_CHECK_ALLOWANCE: 'pairsCheckAllowance',
  PAIRS_SET_SETTINGS: 'pairsSetSettings',
};
export const PAIRS_MUTATION_TYPES = {
  SET_STATE: 'setState',
  SET_TOKEN: 'setToken',
  SET_AMOUNT: 'setAmount',
  SET_ESTIMATED: 'setEstimated',
  SET_ALLOWANCE: 'setAllowance',
};

const state = {
  loading: true,
  pendingTransaction: false,
  inputA: {
    token: null,
    amount: null,
    estimated: false,
    hasAllowance: false,
  },
  inputB: {
    token: null,
    amount: null,
    estimated: false,
    hasAllowance: false,
  },
  pair: [],
  settings: {
    slippageTolerance: SLIPPAGE_TOLERANCE,
    transactionDeadline: TRANSACTION_DEADLINE,
  },
  errors: {},
};

const getters = {
  getPairsToken: state => field => {
    return state[field].token;
  },
  getPairsAmount: state => field => {
    return state[field].amount;
  },
  getPairsEstimated: state => field => {
    return state[field].estimated;
  },
  getPairsAllowance: state => field => {
    return state[field].hasAllowance;
  },
  getPairsSettings: state => {
    return state.settings;
  },
  isFormFilled: state => {
    return (
      state[PairFields.InputA].token instanceof Token &&
      state[PairFields.InputB].token instanceof Token
    );
  },
  isAmountFilled: state => {
    return state[PairFields.InputA].amount && state[PairFields.InputB].amount;
  },
  hasPair: state => {
    return state.pair.length > 0;
  },
  getErrors: state => field => {
    if (field) return state.errors[field];
    return Object.keys(state.errors).findIndex(error => state.errors[error] === true) > -1;
  },
  getPair: state => {
    return state.pair.length > 0 ? state.pair[0] : null;
  },
};
const actions = {
  async [PAIRS_ACTION_TYPES.PAIRS_RESET_STATE]({ commit }) {
    commit(PAIRS_MUTATION_TYPES.SET_STATE, {
      inputA: {
        token: null,
        amount: null,
        estimated: false,
        hasAllowance: false,
      },
      inputB: {
        token: null,
        amount: null,
        estimated: false,
        hasAllowance: false,
      },
      pair: [],
      errors: {},
    });
  },
  async [PAIRS_ACTION_TYPES.PAIRS_SET_SETTINGS]({ commit, dispatch }, { settings }) {
    commit(PAIRS_MUTATION_TYPES.SET_STATE, { settings });
    await dispatch(PAIRS_ACTION_TYPES.PAIRS_SET_PAIR);
  },
  async [PAIRS_ACTION_TYPES.PAIRS_SET_TOKEN]({ commit, dispatch }, { field, token }) {
    commit(PAIRS_MUTATION_TYPES.SET_TOKEN, { field, token });
    await dispatch(PAIRS_ACTION_TYPES.PAIRS_SET_PAIR);
  },
  async [PAIRS_ACTION_TYPES.PAIRS_SET_AMOUNT]({ commit, dispatch }, { field, amount }) {
    commit(PAIRS_MUTATION_TYPES.SET_AMOUNT, { field, amount });
    commit(PAIRS_MUTATION_TYPES.SET_ESTIMATED, { field: getInverseField(field), estimated: true });
    await dispatch(PAIRS_ACTION_TYPES.PAIRS_SET_PAIR);
  },
  async [PAIRS_ACTION_TYPES.PAIRS_VALIDATE]({ state, rootState, commit }) {
    let errors = {
      [PairFields.InputA]: false,
      [PairFields.InputB]: false,
      form: false,
    };
    commit(PAIRS_MUTATION_TYPES.SET_STATE, { errors });
    const tokenABalance = rootState.tokens.balances[state[PairFields.InputA].token.symbol]?.balance;
    const tokenBBalance = rootState.tokens.balances[state[PairFields.InputB].token.symbol]?.balance;

    const tokenAAmount = new TokenAmount(
      state[PairFields.InputA].token,
      parseUnits(
        state[PairFields.InputA].amount || '0',
        state[PairFields.InputA].token.decimals,
      ).toString(),
    );
    const tokenBAmount = new TokenAmount(
      state[PairFields.InputB].token,
      parseUnits(
        state[PairFields.InputB].amount || '0',
        state[PairFields.InputB].token.decimals,
      ).toString(),
    );

    errors = {
      [PairFields.InputA]: tokenAAmount.greaterThan(tokenABalance),
      [PairFields.InputB]: tokenBAmount.greaterThan(tokenBBalance),
      form: tokenAAmount.equalTo(BN_ZERO) || tokenBAmount.equalTo(BN_ZERO),
    };
    commit(PAIRS_MUTATION_TYPES.SET_STATE, { errors });
  },
  async [PAIRS_ACTION_TYPES.PAIRS_SET_PAIR]({ state, dispatch, commit, rootState }) {
    if (!checkHasTwoTokens(state[PairFields.InputA].token, state[PairFields.InputB].token))
      return [];
    if (!getEstimatedField(state)) {
      commit(PAIRS_MUTATION_TYPES.SET_ESTIMATED, { field: PairFields.InputB, estimated: true });
    }
    const pair = rootState.routes.pairs.filter(
      pair =>
        pair.involvesToken(state[PairFields.InputA].token) &&
        pair.involvesToken(state[PairFields.InputB].token),
    );

    if (pair.length > 0) {
      const estimatedField = getEstimatedField(state) || PairFields.InputB;
      const inverseField = getInverseField(estimatedField);
      if (parseUnits(state[inverseField].amount || '0', state[inverseField].token.decimals).gt(0)) {
        const ratio = pair[0].priceOf(state[inverseField].token);
        const estimatedAmount = new BigNumber(state[inverseField].amount || 0).multipliedBy(
          ratio.toFixed(state[estimatedField].token.decimals),
        );
        commit(PAIRS_MUTATION_TYPES.SET_AMOUNT, {
          field: estimatedField,
          amount: estimatedAmount.toFixed(state[estimatedField].token.decimals),
        });
      }
    }
    commit(PAIRS_MUTATION_TYPES.SET_STATE, { pair });
    dispatch(PAIRS_ACTION_TYPES.PAIRS_VALIDATE);
  },
  async [PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE](
    { rootState, commit },
    { field, token, address, amount, sendApprove },
  ) {
    console.log('allowance', field, token, address, amount, sendApprove);
    if (token.isWETHToken() || token.isBaseToken()) {
      if (field) {
        commit(PAIRS_MUTATION_TYPES.SET_ALLOWANCE, { field, allowance: true });
      }
      return true;
    }
    console.log(1);
    const tokenContract = getErc20Contract(address, getInstance().web3.getSigner());
    const allowance = await tokenContract.allowance(rootState.wallet.account, getRouterAddress());
    const jsbiAllowance = ethersToJSBI(allowance);
    const inputAmount = new TokenAmount(token, parseUnits(amount, token.decimals).toString());

    if (inputAmount.greaterThan(jsbiAllowance) && sendApprove === true) {
      console.log(2);
      const result = await tokenContract.approve(
        getRouterAddress(),
        SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
      );
      await result.wait().then(function () {
        if (field) {
          commit(PAIRS_MUTATION_TYPES.SET_ALLOWANCE, { field, allowance: true });
        }
      });
    } else if (inputAmount.lessThan(jsbiAllowance)) {
      console.log(3);
      if (field) {
        commit(PAIRS_MUTATION_TYPES.SET_ALLOWANCE, { field, allowance: true });
      }
    }
  },
  async [PAIRS_ACTION_TYPES.PAIRS_ADD_LIQUIDITY]({ state, dispatch, rootState }) {
    try {
      const tokenA = state[PairFields.InputA].token;
      const tokenB = state[PairFields.InputB].token;
      const amountA = state[PairFields.InputA].amount;
      const amountB = state[PairFields.InputB].amount;
      const inputAAmount = parseUnits(amountA, tokenA.decimals).toString();
      const inputBAmount = parseUnits(amountB, tokenB.decimals).toString();
      console.log(tokenA, tokenB, amountA, amountB, inputAAmount, inputBAmount);
      let pair: Pair = state.pair[0];
      if (!pair) {
        pair = new Pair(
          NO_CONTRACT_ADDRESS,
          new TokenAmount(tokenA, inputAAmount),
          new TokenAmount(tokenB, inputBAmount),
          BN_ZERO,
          BN_ZERO,
        );
      }
      console.log(
        'pair.reserve0.raw.toString()',
        pair.reserve0.raw.toString(),
        pair.reserve0.toExact(),
      );
      //TODO MAKE CHECK IF BASE TOKEN (use this, or if needed to check BNB, ETH use isBaseToken())
      if (
        state[PairFields.InputA].token.isBaseToken() ||
        state[PairFields.InputB].token.isBaseToken()
      ) {
        return await dispatch(PAIRS_ACTION_TYPES.PAIRS_ADD_LIQUIDITY_ETH, pair);
      }

      await dispatch(PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE, {
        token: tokenA,
        address: tokenA.address,
        amount: amountA,
        field: PairFields.InputA,
        sendApprove: true,
      });
      await dispatch(PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE, {
        token: tokenB,
        address: tokenB.address,
        amount: amountB,
        field: PairFields.InputB,
        sendApprove: true,
      });

      const amountAMin = new BigNumber(inputAAmount)
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const amountBMin = new BigNumber(inputBAmount)
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();

      const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
      const result = await routerContract.addLiquidity(
        tokenA.address,
        tokenB.address,
        inputAAmount,
        inputBAmount,
        amountAMin,
        amountBMin,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
      );
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async [PAIRS_ACTION_TYPES.PAIRS_ADD_LIQUIDITY_ETH]({ state, rootState, dispatch }, pair: Pair) {
    console.log('ETH');
    try {
      console.log('pair', pair);
      const otherToken = pair.token0.isBaseToken() ? pair.reserve1 : pair.reserve0;
      const tokenField = state[PairFields.InputA].token.equals(otherToken.token)
        ? PairFields.InputB
        : PairFields.InputA;
      const otherTokenField = state[PairFields.InputA].token.equals(otherToken.token)
        ? PairFields.InputA
        : PairFields.InputB;

      const tokenFieldBase = state[tokenField].token;
      const tokenFieldOther = state[PairFields.InputB].token;
      const amountA = state[tokenField].amount;
      const amountB = state[otherTokenField].amount;
      const inputBaseAmount = parseUnits(amountA, tokenFieldBase.decimals).toString();
      const inputOtherAmount = parseUnits(amountB, tokenFieldOther.decimals).toString();

      await dispatch(PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE, {
        token: otherToken.token,
        address: otherToken.token.address,
        amount: otherToken.raw.toString(),
        sendApprove: true,
      });

      const amountTokenMin = new BigNumber(inputOtherAmount)
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const amountETHMin = new BigNumber(inputBaseAmount)
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const overrides = {
        value: inputBaseAmount,
      };

      const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
      const result = await routerContract.addLiquidityETH(
        otherToken.token.address,
        inputOtherAmount,
        amountTokenMin,
        amountETHMin,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
        overrides,
      );
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async [PAIRS_ACTION_TYPES.PAIRS_WITHDRAW]({ dispatch, rootState }, { pair, amount }) {
    //TODO MAKE CHECK IF BASE TOKEN (use this, or if needed to check BNB, ETH use isBaseToken())
    if (pair.token0.isBaseToken() || pair.token1.isBaseToken()) {
      return await dispatch(PAIRS_ACTION_TYPES.PAIRS_WITHDRAW_ETH, { pair, amount });
    }

    try {
      await dispatch(PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE, {
        token: pair.liquidityToken,
        address: pair.liquidityToken.address,
        amount,
        sendApprove: true,
      });

      const liquidity = parseUnits(amount, pair.liquidityToken.decimals).toString();
      // amountAMin = ((liquidity * reserve_A) / lpToken.totalSupply()) * (1 - slippage_percent / 100)
      // amountBMin = ((liquidity * reserve_B) / lpToken.totalSupply()) * (1 - slippage_percent / 100)
      const amountAMin = new BigNumber(
        new BigNumber(amount)
          .multipliedBy(pair.reserve0.toExact())
          .dividedBy(pair.totalSupply.toExact()),
      )
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const amountBMin = new BigNumber(
        new BigNumber(amount)
          .multipliedBy(pair.reserve1.toExact())
          .dividedBy(pair.totalSupply.toExact()),
      )
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();

      const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
      const result = await routerContract.removeLiquidity(
        pair.token0.address,
        pair.token1.address,
        liquidity,
        amountAMin,
        amountBMin,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
      );
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      await Promise.reject(e);
    }
  },
  async [PAIRS_ACTION_TYPES.PAIRS_WITHDRAW_ETH]({ dispatch, rootState }, { pair, amount }) {
    try {
      await dispatch(PAIRS_ACTION_TYPES.PAIRS_CHECK_ALLOWANCE, {
        token: pair.liquidityToken,
        address: pair.liquidityToken.address,
        amount,
        sendApprove: true,
      });

      const baseTokenReserve = pair.token0.isBaseToken() ? pair.reserve0 : pair.reserve1;
      const otherTokenReserve = pair.token0.isBaseToken() ? pair.reserve1 : pair.reserve0;

      const liquidity = parseUnits(amount, pair.liquidityToken.decimals).toString();
      // amountTokenMin = ((liquidity * reserve_Token) / lpToken.totalSupply()) * (1 - slippage_percent / 100)
      // amountETHMin = ((liquidity * reserve_WETH) / lpToken.totalSupply()) * (1 - slippage_percent / 100)
      const amountTokenMin = new BigNumber(liquidity)
        .multipliedBy(otherTokenReserve.raw.toString())
        .dividedBy(pair.totalSupply.raw.toString())
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const amountETHMin = new BigNumber(liquidity)
        .multipliedBy(baseTokenReserve.raw.toString())
        .dividedBy(pair.totalSupply.raw.toString())
        .multipliedBy(
          new BigNumber(1).minus(new BigNumber(state.settings.slippageTolerance).dividedBy(100)),
        )
        .integerValue(BigNumber.ROUND_DOWN)
        .toFixed();
      const routerContract = getRouterContract(getRouterAddress(), getInstance().web3.getSigner());
      const result = await routerContract.removeLiquidityETH(
        pair.token0.isBaseToken() ? pair.token1.address : pair.token0.address,
        liquidity,
        amountTokenMin,
        amountETHMin,
        rootState.wallet.account,
        safeDateNowPlusEstimatedMinutes(state.settings.transactionDeadline),
      );

      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      await Promise.reject(e);
    }
  },
};
const mutations = {
  [PAIRS_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  async [PAIRS_MUTATION_TYPES.SET_TOKEN](_state, { field, token }) {
    _state[field].token = token;
  },
  async [PAIRS_MUTATION_TYPES.SET_AMOUNT](_state, { field, amount }) {
    _state[field].amount = amount;
  },
  async [PAIRS_MUTATION_TYPES.SET_ALLOWANCE](_state, { field, allowance }) {
    _state[field].hasAllowance = allowance;
    console.log('hasAllowance', _state);
  },
  async [PAIRS_MUTATION_TYPES.SET_ESTIMATED](_state, payload) {
    _state[payload.field].estimated = payload.estimated;
    _state[getInverseField(payload.field)].estimated = false;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
