import { Rounding, _100 } from '../../constants';
import { Fraction } from './fraction';

const _100_PERCENT = new Fraction(_100);

export class Percent extends Fraction {
  // eslint-disable-next-line @typescript-eslint/ban-types
  public toSignificant(significantDigits = 5, format?: object, rounding?: Rounding): string {
    return this.multiply(_100_PERCENT).toSignificant(significantDigits, format, rounding);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public toFixed(decimalPlaces = 2, format?: object, rounding?: Rounding): string {
    return this.multiply(_100_PERCENT).toFixed(decimalPlaces, format, rounding);
  }
}
