import injected from '@/helpers/connectors/snapshot-labs/injected';
import nufi from '@/helpers/connectors/snapshot-labs/nufi';
import walletconnect from '@snapshot-labs/lock/connectors/walletconnect';
import { DEFAULT_CHAIN_ID } from '@/constants/DEFAULT_CHAIN_ID';
import { DEFAULT_NETWORK_ID, NETWORK_ENGINE } from '@/helpers/networkParams.helper';

const baseNetworkId = Number(DEFAULT_NETWORK_ID) ?? DEFAULT_CHAIN_ID;
const networkName = baseNetworkId === DEFAULT_CHAIN_ID ? 'mainnet' : 'testnet';
const defaultNetworkRPC = NETWORK_ENGINE;

export const CONNECTORS_LIST = {
  injected: {
    id: 'injected',
    name: 'MetaMask',
    icon: '/images/connectors/metamask-icon.svg',
    detect: ['isMobile', 'isBrowser'],
  },
  nufi: {
    id: 'nufi',
    name: 'NuFi',
    icon: '/images/connectors/nufi.svg',
    link: 'https://nu.fi/',
    detect: ['isMobile', 'isBrowser'],
  },
  walletconnect: {
    id: 'walletconnect',
    name: 'WalletConnect',
    network: networkName,
    chainId: baseNetworkId,
    icon: '/images/connectors/walletconnect.svg',
    options: {
      rpcUrl: defaultNetworkRPC,
      network: networkName,
      chainId: baseNetworkId,
      rpc: {
        [baseNetworkId]: defaultNetworkRPC,
      },
      qrcodeModalOptions: {
        mobileLinks: ['metamask'],
        desktopLinks: [],
      },
    },
    detect: ['isMobile', 'isBrowser'],
  },
};

const options: any = { connectors: [] };
const lockConnectors = {
  injected,
  nufi,
  walletconnect,
};

Object.entries(CONNECTORS_LIST).forEach((connector: any) => {
  options.connectors.push({
    key: connector[0],
    connector: lockConnectors[connector[0]],
    options: connector[1].options,
  });
});

export default options;
