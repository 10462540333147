import erc20 from '@/data/abi/erc20.json';
import multicall from '@/utils/multicall';
import { BigNumber } from '@ethersproject/bignumber';
import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';

export type TotalValueResponse = {
  totalValue: string[];
  totalValueUSD: string[];
};

export async function fetchVolumes(portfolioAddresses: string[]): Promise<string[]> {
  const volumeRequest = await fetch(`${NETWORK_BACKEND_URL + 'portfolio/volume'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      portfolio: portfolioAddresses,
      period: 30,
    }),
  });
  const volumesJson: { volume: string[] } = await volumeRequest.json();
  return volumesJson.volume;
}

export async function fetchFees(portfolioAddresses: string[]): Promise<string[]> {
  const feeRequest = await fetch(`${NETWORK_BACKEND_URL + 'portfolio/fee'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      portfolio: portfolioAddresses,
      period: 30,
    }),
  });
  const feesJson: { fee: string[] } = await feeRequest.json();
  return feesJson.fee;
}

export async function fetchPrices(
  portfolioBaseTokens: string[],
  baseTokenAddress: string,
): Promise<string[]> {
  const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getPrices'}`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      signature: false,
      tokens: portfolioBaseTokens,
      baseToken: baseTokenAddress,
    }),
  });

  const pricesJson: { price: string[] } = await pricesRequest.json();
  return pricesJson.price;
}

export async function fetchTotalSupply(lpTokenAddresses: string[]): Promise<BigNumber[]> {
  const callsTotalSupply = lpTokenAddresses.map(address => ({
    address,
    name: 'totalSupply',
  }));

  return multicall(erc20, callsTotalSupply);
}

export async function fetchPortfolioTokensOwned(
  lpTokenAddresses: string[],
  userAddress: string,
): Promise<Promise<BigNumber[][]>> {
  const callsTotalSupply = lpTokenAddresses.map(address => ({
    address,
    name: 'balanceOf',
    params: [userAddress],
  }));

  return multicall(erc20, callsTotalSupply);
}
