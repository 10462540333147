import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import {
  getErc20Contract,
  getMinterContract,
  getMinterTvlLevels,
  getMinterAprWeights,
  getPortfolioAndPairRegistryContract,
  getStakingContract,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import {
  BN_ZERO,
  ethersToJSBI,
  SOLIDITY_TYPE_MAXIMA,
  SolidityType,
  BOT_USD_DECIMALS,
} from '@/sdk/constants';
import JSBI from 'jsbi';
import { Staking } from '@/sdk/entities/staking';
import { parseUnits } from '@ethersproject/units';
import { AUTO_STAKING_MUTATION_TYPES } from '@/store/modules/staking/autoStaking.module';
import { GLOBAL_GETTERS } from '@/store/modules/global.getters';
import { fromWei } from '@/sdk/utils';
import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';
import { dateNowInSeconds } from '@/helpers/utils';

export const STAKING_ACTION_TYPES = {
  STAKING_INIT: 'stakingInit',
  GET_STATUS_STAKING: 'getStatusStaking',
  GET_TVL_LEVELS: 'getTvlLevels',
  GET_APR_WEIGHTS: 'getAprWeights',
  UPDATE_STAKING_POOLS: 'updateStakingPools',
  ENABLE_STAKING: 'enableStaking',
  CHECK_ALLOWANCE: 'checkAllowance',
  STAKE_LQF: 'stakeLqf',
  UNSTAKE_LQF: 'unStakeLqf',
  STAKING_HARVEST: 'stakingHarvest',
  STAKING_COMPOUND: 'stakingCompound',
};

export const STAKING_MUTATION_TYPES = {
  SET_STATE: 'setState',
  ADD_POOL: 'addPool',
};

const state = {
  isStoreReady: false,
  hasError: false,
  statusStaking: [],
  pool: {},
  tvlLevels: [],
  aprWeights: [],
};
const getters = {
  getStakingTableData: state => {
    console.log('state.pool', state.pool);
    const tableDataArray = Object.values(state.pool || {});
    if (tableDataArray.length > 0) {
      return tableDataArray;
    }
    return [];
  },
};
const actions = {
  async [STAKING_ACTION_TYPES.STAKING_INIT]({ commit, dispatch, getters }) {
    commit(STAKING_MUTATION_TYPES.SET_STATE, {
      isStoreReady: false,
      hasError: false,
      statusStaking: [],
      pool: {},
    });
    try {
      dispatch(STAKING_ACTION_TYPES.GET_TVL_LEVELS);
      dispatch(STAKING_ACTION_TYPES.GET_APR_WEIGHTS);
      await dispatch(STAKING_ACTION_TYPES.GET_STATUS_STAKING);
      await dispatch(STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS);
      const isReceivedStakingData = getters.getStakingTableData.length !== 0;
      commit(STAKING_MUTATION_TYPES.SET_STATE, { isStoreReady: isReceivedStakingData });
      if (!isReceivedStakingData) {
        dispatch(STAKING_ACTION_TYPES.STAKING_INIT);
      }
    } catch (e) {
      console.error('[STAKING] Happen error: ', e);
      commit(STAKING_MUTATION_TYPES.SET_STATE, { hasError: true });
    }
  },
  async [STAKING_ACTION_TYPES.GET_STATUS_STAKING]({ commit, rootState }) {
    console.log('STAKING_ACTION_TYPES.GET_STATUS_STAKING START');
    try {
      const minterContract = getMinterContract();
      const statusStaking = await minterContract.getStatusStaking(
        rootState.wallet.account,
        dateNowInSeconds(),
      );
      console.log('statusStaking', statusStaking);
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { statusStaking });
    } catch (e) {
      console.debug(e);
    }
    console.log('STAKING_ACTION_TYPES.GET_STATUS_STAKING END');
  },
  async [STAKING_ACTION_TYPES.GET_TVL_LEVELS]({ commit }) {
    try {
      const tvlLevels = await getMinterTvlLevels();
      commit(STAKING_MUTATION_TYPES.SET_STATE, { tvlLevels });
    } catch (e) {
      console.debug(e);
    }
  },
  async [STAKING_ACTION_TYPES.GET_APR_WEIGHTS]({ commit }) {
    try {
      const aprWeights = await getMinterAprWeights();
      commit(STAKING_MUTATION_TYPES.SET_STATE, { aprWeights });
    } catch (e) {
      console.debug(e);
    }
  },
  async [STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS]({ state, commit, rootGetters }) {
    console.log('STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS START');
    const baseTokenAddress = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN_ADDRESS];
    // TODO: we use this token like USD token (USD decimals for `getPrices` API)
    // const baseToken = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN];
    const nativeToken = rootGetters[GLOBAL_GETTERS.GET_NATIVE_TOKEN];
    const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getPrices'}`, {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        signature: false,
        tokens: [nativeToken.address],
        baseToken: baseTokenAddress,
      }),
    });
    const pricesResponse = await pricesRequest.json();
    console.log('pricesResponse nativeToken', pricesResponse);
    await Promise.all(
      state.statusStaking.map(async statusStaking => {
        const profileContract = getPortfolioAndPairRegistryContract();
        const totalStaked = await profileContract.getManualYieldPoolTotalStaked();
        // price: native token (BLUES) in USD
        const nativeTokenPriceInBase = fromWei(pricesResponse.price[0], BOT_USD_DECIMALS);
        console.log('nativeTokenPriceInBase', nativeTokenPriceInBase.toString());
        const newStaking = new Staking(statusStaking, nativeTokenPriceInBase, totalStaked);
        commit(STAKING_MUTATION_TYPES.ADD_POOL, newStaking);
      }),
    );
    console.log('STAKING_ACTION_TYPES.UPDATE_STAKING_POOLS END');
  },
  async [STAKING_ACTION_TYPES.CHECK_ALLOWANCE]({ rootState }, { pool, amount }): Promise<void> {
    try {
      if (!getInstance()?.web3) {
        console.warn('[STAKING:CHECK:ALLOWANCE] Can not get signer.');
        return;
      }
      console.log('[STAKING:CHECK:ALLOWANCE]: pool: ', pool);

      const tokenContract = getErc20Contract(pool.token, getInstance().web3.getSigner());
      const allowance = await tokenContract.allowance(rootState.wallet.account, pool.farm);
      const jsbiAllowance = ethersToJSBI(allowance);
      const jsbiAmount = ethersToJSBI(parseUnits(amount ?? '0'));

      const needApprove =
        JSBI.equal(jsbiAllowance, BN_ZERO) || JSBI.greaterThan(jsbiAmount, jsbiAllowance);
      console.groupCollapsed(
        `[STAKING:CHECK:ALLOWANCE] Need approve ${pool.token} : ${needApprove}`,
      );
      console.log(`${pool.token}     input: `, jsbiAmount.toString());
      console.log(`${pool.token} allowance: `, jsbiAllowance.toString());
      console.groupEnd();

      if (needApprove) {
        const approveRequest = await transactionWithEstimatedGas(tokenContract, 'approve', [
          pool.farm,
          SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
        ]);

        await approveRequest.wait();
      }
    } catch (ex) {
      console.debug('[STAKING:CHECK:ALLOWANCE:ERROR] Error: ', ex);
      throw ex;
    }
  },
  async [STAKING_ACTION_TYPES.STAKE_LQF](_, { pool, amount }) {
    console.log('STAKING_ACTION_TYPES.STAKE_LQF', pool, amount);
    try {
      const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());
      const bigIntAmount = parseUnits(amount).toString();
      const result = await transactionWithEstimatedGas(stakingContract, 'deposit', [bigIntAmount]);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [STAKING_ACTION_TYPES.UNSTAKE_LQF](_, { pool, amount }) {
    console.log('STAKING_ACTION_TYPES.UNSTAKE_LQF', pool, amount);
    try {
      const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());
      const bigIntAmount = parseUnits(amount).toString();
      const result = await transactionWithEstimatedGas(stakingContract, 'withdraw', [bigIntAmount]);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [STAKING_ACTION_TYPES.STAKING_COMPOUND](_, { pool }) {
    console.log('STAKING_ACTION_TYPES.STAKING_COMPOUND', pool);
    try {
      const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());
      const result = await transactionWithEstimatedGas(stakingContract, 'compound', []);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [STAKING_ACTION_TYPES.STAKING_HARVEST](_, { pool }) {
    console.log('STAKING_ACTION_TYPES.STAKING_HARVEST', pool);
    try {
      const stakingContract = getStakingContract(pool.farm, getInstance().web3.getSigner());
      const result = await transactionWithEstimatedGas(stakingContract, 'getReward', []);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
};
const mutations = {
  [STAKING_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [STAKING_MUTATION_TYPES.ADD_POOL](_state, payload) {
    _state.pool[payload.token] = payload;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
