import { ChainId, TradeType } from '@/sdk/constants';
import { CurrencyAmount } from '@/sdk/entities/fractions/currencyAmount';
import { Percent } from '@/sdk/entities/fractions/percent';
import { Price } from '@/sdk/entities/fractions/price';
import { ITrade } from '@/sdk/entities/models/trade.intraface';
import { Route } from '@/sdk/entities/route';
import { Token } from '@/sdk/entities/token';

export class WrapTrade implements ITrade {
  public readonly route: Route;

  public readonly inputAmount: CurrencyAmount;

  public readonly outputAmount: CurrencyAmount;

  public readonly executionPrice: Price;

  public readonly nextMidPrice: Price;

  public readonly priceImpact: Percent;

  constructor(
    public readonly tradeType: TradeType,
    fromToken: Token,
    toToken: Token,
    amount: CurrencyAmount,
  ) {
    if (!this.checkIfTradeIsWrapTrade(fromToken, toToken)) {
      throw new Error(`WrapTrade can be created only for eth-weth or weth-eth swap.`);
    }

    this.route = {
      pairs: [],
      path: [fromToken, toToken],
      input: fromToken,
      output: toToken,
      midPrice: new Price(fromToken, toToken, '1', '1'),
      get chainId(): ChainId {
        return fromToken.chainId;
      },
    } as Route;

    this.inputAmount = amount;
    this.outputAmount = amount;
    this.executionPrice = new Price(fromToken, toToken, '1', '1');
    this.nextMidPrice = new Price(fromToken, toToken, '1', '1');
    this.priceImpact = new Percent('0', '1');
  }

  public maximumAmountIn(): CurrencyAmount {
    return this.inputAmount;
  }

  public minimumAmountOut(): CurrencyAmount {
    return this.outputAmount;
  }

  public isCrossChain() {
    return false;
  }

  public isLocal() {
    return true;
  }

  public getDestinationChainId(): ChainId | 0 {
    return 0;
  }

  private checkIfTradeIsWrapTrade(fromToken: Token, toToken: Token): boolean {
    return (
      (fromToken.isETHToken() && toToken.isWETHToken()) ||
      (fromToken.isWETHToken() && toToken.isETHToken())
    );
  }
}
