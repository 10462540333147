import { PROD_HOSTNAME } from '@/constants/appParameters';

export function isProductionURL() {
  return window.location.hostname === PROD_HOSTNAME;
}

export function getSearchParams() {
  const fullUrl = window.location.toString();
  const searchParamsStartPosition = fullUrl.indexOf('?');
  if (searchParamsStartPosition !== -1) {
    return new URLSearchParams(fullUrl.slice(searchParamsStartPosition));
  }
  return new URLSearchParams();
}

export function getURLParam(param: string) {
  return getSearchParams()?.get(param) || '';
}

export function getUrlWithoutParam(param: string) {
  const fullUrl = window.location.toString();
  const searchParamsStartPosition = fullUrl.indexOf('?');

  const searchParams = getSearchParams();
  searchParams.delete(param);

  if (searchParams.toString()) {
    return fullUrl.slice(0, searchParamsStartPosition) + '?' + searchParams;
  }

  if (searchParamsStartPosition !== -1) {
    return fullUrl.slice(0, searchParamsStartPosition);
  }

  return fullUrl;
}

export function updatePageUrl(url: string) {
  location.assign(url);
  window.location.reload();
}
