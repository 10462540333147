import LockInjectedConnector from '@snapshot-labs/lock/connectors/injected';

export default class Connector extends LockInjectedConnector {
  /**
   * @override
   */
  async isLoggedIn() {
    // FIX: fix for @snapshot-labs/lock
    console.warn(
      '[INJECTED:LOCK:CONNECTOR:OVERRIDE]: ',
      'we override `isLoggedIn` method in Injected class of @snapshot-labs.',
    );
    if (!window['ethereum']) return false;
    let [selectedAddress] = await window['ethereum'].request({ method: 'eth_accounts' });
    if (selectedAddress) return true;
    await new Promise(r => setTimeout(r, 400));
    [selectedAddress] = await window['ethereum'].request({ method: 'eth_accounts' });
    return !!selectedAddress;
  }
}
