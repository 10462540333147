import { BLOCKCHAIN_NAME } from '@/store/modules/bridge/constants/BLOCKCHAIN_NAME';
import { Blockchain } from '@/store/modules/bridge/models/blockchain';

export const BLOCKCHAINS: Record<BLOCKCHAIN_NAME, Blockchain> = {
  [BLOCKCHAIN_NAME.CARDANO]: {
    id: 1,
    name: BLOCKCHAIN_NAME.CARDANO,
    label: 'Cardano',
    icon: 'cardano',
    nativeToken: {
      symbol: 'ADA',
    },
  },
  [BLOCKCHAIN_NAME.MILKOMEDA]: {
    id: 2001,
    name: BLOCKCHAIN_NAME.MILKOMEDA,
    label: 'Milkomeda',
    icon: 'milkomeda',
    nativeToken: {
      symbol: 'milkADA',
    },
  },
  [BLOCKCHAIN_NAME.TESTNET_CARDANO]: {
    id: 0,
    name: BLOCKCHAIN_NAME.TESTNET_CARDANO,
    label: 'Cardano testnet',
    icon: 'cardano',
    nativeToken: {
      symbol: 'tADA',
    },
  },
  [BLOCKCHAIN_NAME.TESTNET_MILKOMEDA]: {
    id: 200101,
    name: BLOCKCHAIN_NAME.TESTNET_MILKOMEDA,
    label: 'Milkomeda testnet',
    icon: 'milkomeda',
    nativeToken: {
      symbol: 'milkTADA',
    },
  },
};

export function getBlockchainByName(name: BLOCKCHAIN_NAME): Blockchain {
  const blockchain = BLOCKCHAINS[name];

  if (!blockchain) {
    throw new Error(`Blockchain ${name} was not found.`);
  }

  return blockchain;
}

export function getBlockchainById(id: number): Blockchain {
  const blockchain = Object.values(BLOCKCHAINS).find(blockchain => blockchain.id === id);

  if (!blockchain) {
    throw new Error(`Blockchain ${name} was not found.`);
  }

  return blockchain;
}

export function getNativeBlockchainToken(name: BLOCKCHAIN_NAME): string {
  const blockchain = getBlockchainByName(name);

  return blockchain.nativeToken.symbol;
}
