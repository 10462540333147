import { Pair } from '@/sdk/entities/pair';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { BLOCKS_PER_YEAR, DEFAULT_TOKEN_DECIMAL } from '@/sdk/constants';
import { BIG_ZERO, ethersToBigNumber } from '@/utils/bigNumber';
import BigNumber from 'bignumber.js';
import { FarmSourceType } from './farmSource';
import { Token } from './token';

export class Farm {
  public readonly multiplier: BigNumber;
  public readonly rewardPerBlock: BigNumber;
  public readonly accDeposited: BigNumber;
  public readonly deposited: BigNumber;
  public readonly earned: BigNumber;
  public readonly token: string;
  public readonly farm: string;
  public readonly pair: Pair;
  public readonly nativeTokenPriceInBase: BigNumber;
  public readonly token0Price: TokenAmount;
  public readonly token1Price: TokenAmount;
  public readonly fee: BigNumber;

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    statusFarms: object,
    pair: Pair,
    prices: TokenAmount[],
    nativeTokenPriceInBase: BigNumber,
    fee: string,
  ) {
    console.log('statusFarms', statusFarms);
    this.multiplier = ethersToBigNumber(statusFarms['multiplier']);
    // console.log('multiplier', this.multiplier.toString());
    this.rewardPerBlock = ethersToBigNumber(statusFarms['rewardPerBlock']);
    // console.log('rewardPerBlock', this.rewardPerBlock.toString());
    this.accDeposited = ethersToBigNumber(statusFarms['accDeposited']);
    // console.log('accDeposited', this.accDeposited.toString());
    this.deposited = ethersToBigNumber(statusFarms['deposited']);
    // console.log('deposited', this.deposited.toString());
    this.earned = ethersToBigNumber(statusFarms['earned']);
    // console.log('earned', this.earned.toString());
    this.token = statusFarms['token'];
    this.farm = statusFarms['farm'];
    this.pair = pair;
    this.nativeTokenPriceInBase = nativeTokenPriceInBase;
    this.token0Price = prices[0];
    this.token1Price = prices[1];
    this.fee = new BigNumber(fee);
  }

  public get sourceType(): FarmSourceType {
    return FarmSourceType.PAIR;
  }

  public get liquidityToken(): Token {
    return this.pair.liquidityToken;
  }

  public get tokensOwned(): TokenAmount {
    return this.pair.poolTokensOwned;
  }

  public get printDeposited(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.deposited.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printDepositedUSD(): TokenAmount {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.deposited);
    return new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printLiquidity(): TokenAmount {
    const lpPrice = this.lpTokenPrice().multipliedBy(this.accDeposited);
    return new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarned(): TokenAmount {
    return new TokenAmount(
      this.liquidityToken,
      this.earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printEarnedUSD(): TokenAmount {
    const earned = this.earned.multipliedBy(this.nativeTokenPriceInBase);
    return new TokenAmount(
      this.liquidityToken,
      earned.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }

  public get printMultiplier(): string {
    return this.multiplier.toFixed();
  }

  public get printIPR(): number {
    const rewardPerBlock = this.rewardPerBlock.dividedBy(DEFAULT_TOKEN_DECIMAL);
    const fee = this.fee.dividedBy(DEFAULT_TOKEN_DECIMAL);
    const accDeposited = this.accDeposited.dividedBy(DEFAULT_TOKEN_DECIMAL);

    const aprLeft = rewardPerBlock
      .multipliedBy(this.nativeTokenPriceInBase.multipliedBy(BLOCKS_PER_YEAR))
      .plus(fee.multipliedBy(365).dividedBy(30));
    const aprRight = this.lpTokenPrice().multipliedBy(accDeposited);

    const ipr = aprLeft
      .dividedBy(aprRight)
      .multipliedBy(100)
      .integerValue(BigNumber.ROUND_DOWN)
      .toNumber();
    return ipr !== Infinity && !isNaN(ipr) ? ipr : 0;
  }

  public lpTokenPrice(): BigNumber {
    const reserve0 = new BigNumber(this.pair.reserve0.toExact());
    const reserve1 = new BigNumber(this.pair.reserve1.toExact());
    const price0 = new BigNumber(this.token0Price.toExact());
    const price1 = new BigNumber(this.token1Price.toExact());

    const liquidity = reserve0.multipliedBy(price0).plus(reserve1.multipliedBy(price1));
    return liquidity.dividedBy(this.pair.totalSupply.toExact());
  }

  public calculateUSD(value: string): TokenAmount {
    const lpPrice = this.lpTokenPrice()
      .multipliedBy(value || BIG_ZERO)
      .multipliedBy(DEFAULT_TOKEN_DECIMAL);
    return new TokenAmount(
      this.liquidityToken,
      lpPrice.integerValue(BigNumber.ROUND_DOWN).toFixed(),
    );
  }
}
