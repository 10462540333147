import { validateSolidityTypeInstance } from '@/sdk/utils';
import JSBI from 'jsbi';
import { SolidityType } from '@/sdk/constants';
import { NETWORK_ETH_TOKEN_NAME } from '@/helpers/networkParams.helper';

export class Currency {
  public readonly decimals: number;
  public readonly symbol?: string;
  public readonly name?: string;

  /**
   * The only instance of the base class `Currency`.
   */
  public static readonly ETHER: Currency = new Currency(
    18,
    NETWORK_ETH_TOKEN_NAME,
    NETWORK_ETH_TOKEN_NAME,
  );

  protected constructor(decimals: number, symbol?: string, name?: string) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8);

    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
  }
}

const ETHER = Currency.ETHER;
export { ETHER };
