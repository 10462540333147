import { Directive, DirectiveBinding } from 'vue';
import { GTM_CLASSES } from '@/constants/gtmClasses';

const classAdd = (el: Element, options: DirectiveBinding<GTM_CLASSES | GTM_CLASSES[]>) => {
  if (Array.isArray(options.value)) {
    options.value.forEach(item => el.classList.add(item));
  } else {
    options.arg === 'id' ? (el.id = options.value) : el.classList.add(options.value);
  }
};

const gtmClass: Directive<Element, GTM_CLASSES | GTM_CLASSES[]> = {
  beforeMount(el, binding) {
    classAdd(el, binding);
  },
  updated(el, binding) {
    classAdd(el, binding);
  },
};

export default gtmClass;
