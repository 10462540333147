export enum METAMASK_ERROR_CODES {
  CHAIN_WAS_NOT_ADDED = 4902,
  UNRECOGNIZED_CHAIN_ID = -32603,
}

export enum WALLETS_INSTALL_LINKS {
  Nami = 'https://namiwallet.io/',
  Flint = 'https://flint-wallet.com/',
  Cardwallet = 'https://cwallet.finance/',
  Eternl = 'https://eternl.io/app/mainnet/welcome',
  Typhon = 'https://typhonwallet.io/#/download',
  Yoroi = 'https://yoroi-wallet.com/#/',
}
