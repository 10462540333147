import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';

type PriceResponse = {
  price: string[];
};

export async function getPriceInUSD(symbol: string): Promise<string> {
  const response = await fetch(`${NETWORK_BACKEND_URL}price/token-symbol/vs/usd`, {
    method: 'post',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({
      token: [symbol],
      isHumanReadable: false,
    }),
  });
  const data: PriceResponse = await response.json();

  return data.price[0];
}
