import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ITokenState } from '@/store/modules/tokens/models/token-state.interface';
import { getTokens } from '@/helpers/cross-chain-api';
import { ICrossChainToken } from '@/store/modules/tokens/models/cross-chain-token.interface';
import { ICrossChainTokenItem } from '@/store/modules/tokens/models/cross-chain-token-item.interface';
import { ChainId } from '@/sdk/constants';
import { ITokenDescription, Token } from '@/sdk/entities/token';
import { compareTokenAddresses } from '@/sdk/utils';
import { DEFAULT_NETWORK_ID, NETWORK_USD_TOKEN_ADDRESS } from '@/helpers/networkParams.helper';

const tokens = ref<ITokenState | {}>({});

export const useTokens = defineStore('tokens', () => {
  // loadCrossChainTokens().then(tokensList => (tokens.value = tokensList || []));
  const getTokensListByChainId = (chainId: ChainId): Token[] => {
    return tokens.value[chainId] || [];
  };
  const getTokenByAddressAndChainId = (address: string, chainId: ChainId): Token => {
    const token = tokens.value[chainId]?.find(t => compareTokenAddresses(t.address, address));

    if (!token) {
      throw new Error(`[TOKENS STORE] Token '${address}' was not found.`);
    }

    return token;
  };

  /**
   * @param chainId get tokens for chainId
   * @param filter search token by symbol (filter is object which contains symbol field)
   * @param search search tokens by substring within token symbol
   *
   * @returns Token[]
   */
  const getTokensListByChainIdWithFilter = (
    chainId: ChainId,
    filter?: Partial<Token> | null,
    search?: string,
  ): Token[] => {
    const tokens: Token[] = getTokensListByChainId(chainId);
    if (!tokens?.length) return [];

    if (filter && filter.symbol)
      return tokens.filter(token => token.symbol?.toLowerCase() === filter.symbol?.toLowerCase());

    if (search) {
      return tokens.filter(token =>
        token.symbol?.toLowerCase().includes(search.trim().toLowerCase()),
      );
    }

    return tokens;
  };

  /**
   * @param filter search token by symbol (filter is object which contains symbol field)
   * @param search search tokens by substring within token symbol
   *
   * @returns single Token | map of Tokens
   */
  // TODO: getter from token module
  const getTokensWithFilter = (
    filter?: Partial<Token>,
    search?: string,
  ): Record<string, Token> | Token | null => {
    const tokens: Token[] = getTokensListByChainIdWithFilter(
      DEFAULT_NETWORK_ID! as unknown as ChainId,
      filter,
      search,
    );

    if (!tokens.length) return null;

    const tokensBySymbol = {};
    tokens.forEach(token => (tokensBySymbol[token.symbol!] = token));

    if (filter && filter.symbol) return tokensBySymbol[filter.symbol];

    return tokensBySymbol;
  };

  const filterByPresentLocally = (tokens: Token[] = []): Token[] => {
    return tokens.filter(token => token.description?.isPresentLocally ?? true);
  };

  const getGasToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    return tokens.filter(token => token.isETHToken())[0];
  };

  // NOTE: 'getBaseToken' getter from token module
  const getUSDToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    return tokens.find(token => token.address === NETWORK_USD_TOKEN_ADDRESS)!;
  };

  // NOTE: 'getBaseTokenAddress' getter from token module
  const getUSDTokenAddress = () => {
    return NETWORK_USD_TOKEN_ADDRESS!;
  };

  // NOTE: 'getNativeToken' getter from token module
  const getBLUESToken = () => {
    const tokens: Token[] = getTokensListByChainId(DEFAULT_NETWORK_ID! as unknown as ChainId);
    return tokens.find(token => token.symbol === 'BLUES')!;
  };

  return {
    getBLUESToken,
    getUSDToken,
    getUSDTokenAddress,
    getGasToken,
    getTokensWithFilter,
    getTokensListByChainId,
    getTokensListByChainIdWithFilter,
    getTokenByAddressAndChainId,
    filterByPresentLocally,
  };
});

export async function loadCrossChainTokens(): Promise<ITokenState | {}> {
  const tokensList: ITokenState | {} = {};
  let crossChainTokens: ICrossChainToken[] = [];
  try {
    const crossChainTokensResponse = await getTokens(DEFAULT_NETWORK_ID as unknown as ChainId);
    crossChainTokens = crossChainTokensResponse.token;

    crossChainTokens.forEach((crToken: ICrossChainToken) => {
      Object.entries(crToken.chainTokens).forEach(([chainIdKey, chainTokenInfo]) => {
        if (!tokensList[chainIdKey]) tokensList[chainIdKey] = [];
        // add wrapped gas token or erc20
        const token = createToken(crToken, chainTokenInfo);
        tokensList[chainIdKey].push(token);
        // add gas token
        if (token.isBaseToken() && token.unwrapWETH().isETHToken()) {
          tokensList[chainIdKey].push(token.unwrapWETH());
        }
      });
    });
    console.log('tokensList', tokensList);
    tokens.value = tokensList;
    return tokensList;
  } catch (e) {
    throw Error("Can't get tokens list from cross chain dex.");
  }
}

/**
 * Create a regular token or Wrapped GAS token for chain.
 *
 * @returns {Token} regular token or Wrapped GAS token for chain.
 */
function createToken(crToken: ICrossChainToken, chainTokenInfo: ICrossChainTokenItem): Token {
  const { name, iconLink, decimals, symbol: crSymbol } = crToken;
  const { chainId, address, symbol } = chainTokenInfo;

  const tokenDescription: ITokenDescription = buildTokenDescription(crToken, chainTokenInfo);

  return new Token(
    chainId,
    address,
    decimals,
    chainId === (DEFAULT_NETWORK_ID as unknown as ChainId) ? symbol : crSymbol,
    name,
    '',
    iconLink,
    tokenDescription,
  );
}

/**
 * Return token description
 *
 * @returns {ITokenDescription} description contains token type, cross-chain symbol, gas token if wrapped gas token
 */
function buildTokenDescription(
  crToken: ICrossChainToken,
  chainTokenInfo: ICrossChainTokenItem,
): ITokenDescription {
  const { symbol: crossChainSymbol } = crToken;
  const { gasToken, isPresentLocally } = chainTokenInfo;

  // is gas token for `chainId` from `chainTokenInfo`
  const isGasTokenInTokenChain = !!gasToken;

  return {
    tokenType: isGasTokenInTokenChain ? 'WGAS' : 'ERC20',
    crossChainSymbol,
    isPresentLocally,
    gasToken: isGasTokenInTokenChain ? createGasToken(crToken, chainTokenInfo) : undefined,
  };
}

/**
 * Create GAS token for chain.
 *
 * @returns {Token} GAS token for chain.
 */
function createGasToken(crToken: ICrossChainToken, chainTokenInfo: ICrossChainTokenItem): Token {
  const { symbol: crossChainSymbol, iconLink, decimals } = crToken;
  const { chainId, address, gasToken: gasTokenSymbol, isPresentLocally } = chainTokenInfo;

  return new Token(
    chainId,
    address,
    decimals,
    gasTokenSymbol,
    `${gasTokenSymbol} native token`,
    '',
    iconLink,
    {
      tokenType: 'GAS',
      crossChainSymbol,
      isPresentLocally,
    },
  );
}
