import { LP_TOKEN_DECIMALS } from '@/sdk/entities/constants/LP_TOKEN_DECIMALS';
import { applySlippageInPercents, compareTokenAddresses, fromWei, toWei } from '@/sdk/utils';
import { PortfolioDto } from '@/store/modules/portfolios/models/portfolio-dto.interface';
import { PortfolioTokenDto } from '@/store/modules/portfolios/models/portfolio-token-dto.interface';
import BigNumber from 'bignumber.js';
import { BIG_ZERO, ethersToBigNumber, max, min, toBigNumber } from '@/utils/bigNumber';
import {
  BigintIsh,
  BN_ZERO,
  ChainId,
  PRICE_DECIMALS,
  WEIGHT_MULTIPLIER,
  BOT_USD_DECIMALS,
} from '@/sdk/constants';
import { Token } from '@/sdk/entities/token';
import { TokenAmount } from '@/sdk/entities/fractions/tokenAmount';
import { Pair } from '@/sdk/entities/pair';
import JSBI from 'jsbi';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { useTokens } from '@/store/modules/tokens/useTokens';
import { ethers } from 'ethers';

export class WithdrawTokens {
  token: Token;
  tokenAddress: string;
  checked: boolean;
  limit: TokenAmount;
  limitByAmount: TokenAmount;

  /**
   * [R] withdraw amount in relative units
   */
  private value: BigNumber | null;

  /**
   * [R] withdraw amount in relative units of lp token
   */
  private lpValue: BigNumber;
  price: BigNumber;
  externalPrice: BigNumber;

  /**
   * [W] withdraw amount in wei units
   */
  public get weiValue(): BigNumber | null {
    if (!this.value) {
      return null;
    }
    return toWei(this.value, this.token.decimals);
  }

  /**
   * [W] withdraw amount in wei units of lp token
   */
  public get lpWeiValue(): BigNumber {
    return toWei(this.lpValue, LP_TOKEN_DECIMALS);
  }

  constructor(token: TokenInfo) {
    this.token = token.token;
    this.tokenAddress = token.tokenAddress;
    this.checked = false;
    this.limit = token.amount;
    this.limitByAmount = token.amount;
    this.value = BIG_ZERO;
    this.lpValue = BIG_ZERO;
    this.price = token.price;
    this.externalPrice = token.withdrawEMAPrice;
  }

  public updateValue(value: number | string | BigNumber | null) {
    if (value === null) {
      this.value = null;
      return;
    }
    this.value = new BigNumber(value!);
  }

  public updateLpValue(lpValue: number | string | BigNumber) {
    this.lpValue = new BigNumber(lpValue);
  }

  /**
   * [R] withdraw price of 1 token in base token
   */
  // TODO: [W/W] withdraw price of 1 wei of this token in base token weis.
  public getWithdrawPrice(): BigNumber {
    return max(this.price, this.externalPrice);
  }

  public getMinAmountOutWei(slippageTolerance: number): string {
    if (!this.weiValue) {
      throw new Error('Withdraw token value is null');
    }
    return applySlippageInPercents(this.weiValue, slippageTolerance).toFixed(0);
  }

  public getLimit(): TokenAmount {
    return JSBI.lessThanOrEqual(this.limitByAmount.raw, this.limit.raw)
      ? this.limitByAmount
      : this.limit;
  }
}

export interface FilledTokensObject {
  value: string;
  checked: boolean;
  hasError: boolean;
  hasOnlyBalanceError: boolean;
}

export class TokenInfo {
  public isPresentLocally: boolean;
  public token: Token;
  public tokenAddress: string;
  public amount: TokenAmount;
  public targetWeight: BigNumber;
  public availableOn: string[];
  public readonly baseToken: Token;

  /**
   * [R] price of one token in base currency
   */
  // TODO: [W/W] price of one token wei in base currency weis
  public price: BigNumber;

  /**
   * [R] deposit EMA price of one token
   */
  // TODO: [W/W] deposit EMA price of one token wei
  public depositEMAPrice: BigNumber;

  /**
   * [R] withdraw EMA price of one token
   */
  // TODO: [W/W] withdraw EMA price of one token wei
  public withdrawEMAPrice: BigNumber;

  public depositLimit: BigNumber;
  public withdrawLimit: BigNumber;

  /**
   * [W] token reserve equivalent in base currency wei units
   */
  public get baseTokenAmountEquivalent(): BigNumber {
    // BASE WEI / TOKEN WEI
    const priceInWei = this.price.shiftedBy(this.baseToken.decimals - this.token.decimals);
    // (BASE WEI / TOKEN WEI) * TOKEN WEI => BASE WEI
    return priceInWei.multipliedBy(this.amount.raw.toString());
  }

  constructor(tokenInfo: PortfolioTokenDto, baseToken: Token) {
    this.tokenAddress = tokenInfo.tokenAddress;
    this.isPresentLocally = tokenInfo.isPresentLocally === true;
    this.availableOn = tokenInfo.availableOn ?? [];
    this.baseToken = baseToken;

    const { getTokenByAddressAndChainId } = useTokens();
    this.token = getTokenByAddressAndChainId(this.tokenAddress, this.baseToken.chainId);

    this.amount = new TokenAmount(
      this.token,
      ethersToBigNumber(tokenInfo.amount).integerValue().toString(),
    );
    this.targetWeight = ethersToBigNumber(tokenInfo.targetWeight).dividedBy(WEIGHT_MULTIPLIER);

    const priceShift = this.token.decimals - this.baseToken.decimals;
    this.price = fromWei(ethersToBigNumber(tokenInfo.price), PRICE_DECIMALS).shiftedBy(priceShift);
    this.depositEMAPrice = fromWei(
      ethersToBigNumber(tokenInfo.depositEMAPrice),
      PRICE_DECIMALS,
    ).shiftedBy(priceShift);
    this.withdrawEMAPrice = fromWei(
      ethersToBigNumber(tokenInfo.withdrawEMAPrice),
      PRICE_DECIMALS,
    ).shiftedBy(priceShift);
    this.depositLimit = ethersToBigNumber(tokenInfo.depositLimit);
    this.withdrawLimit = ethersToBigNumber(tokenInfo.withdrawLimit);

    console.groupCollapsed(`=== TokenInfo ${this.token.symbol} | ${this.token.decimals} ===`);
    console.log('baseToken: ', `${this.baseToken.symbol} | ${this.baseToken.decimals}`);
    console.log('amount (raw): ', ethersToBigNumber(tokenInfo.amount).toString());
    console.log('amount: ', this.amount.toFixed());
    console.log('price (raw): ', ethersToBigNumber(tokenInfo.price).toString());
    console.log(`price in [${this.baseToken.symbol}] : `, this.price.toString());
    console.log('depositEMAPrice (raw): ', ethersToBigNumber(tokenInfo.depositEMAPrice).toString());
    console.log(
      `depositEMAPrice in [${this.baseToken.symbol}] : `,
      this.depositEMAPrice.toString(),
    );
    console.log(
      'withdrawEMAPrice (raw): ',
      ethersToBigNumber(tokenInfo.withdrawEMAPrice).toString(),
    );
    console.log(
      `withdrawEMAPrice in [${this.baseToken.symbol}] : `,
      this.withdrawEMAPrice.toString(),
    );
    console.log('depositLimit: ', this.depositLimit.toString());
    console.log('withdrawLimit: ', this.withdrawLimit.toString());
    console.groupEnd();
  }

  public getPortfolioSharePercent(portfolioTotalValueInBase: TokenAmount): BigNumber {
    if (portfolioTotalValueInBase.equalTo('0')) {
      return new BigNumber(0);
    }

    // BASE WEI / TOKEN WEI
    const priceInWei = this.price.shiftedBy(this.baseToken.decimals - this.token.decimals);
    // (BASE WEI / TOKEN WEI) * TOKEN WEI => BASE WEI
    const amountInBaseWei = priceInWei.multipliedBy(this.amount.raw.toString());
    const portfolioTotalValueInBaseWei = portfolioTotalValueInBase.raw.toString();

    return amountInBaseWei.dividedBy(portfolioTotalValueInBaseWei).multipliedBy(100);
  }

  /**
   * [R] Returns deposit token price
   */
  // TODO: [W/W] Returns deposit token wei price.
  public getDepositPrice(): BigNumber {
    return min(this.price, this.depositEMAPrice);
  }

  /**
   * [R] Returns withdraw token price
   */
  // TODO: [W/W] Returns withdraw token wei price.
  public getWithdrawPrice(): BigNumber {
    return max(this.price, this.withdrawEMAPrice);
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getPriceRelative(): BigNumber {
    return this.price;
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getDepositPriceRelative(): BigNumber {
    return this.getDepositPrice();
  }

  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   */
  public getWithdrawPriceRelative(): BigNumber {
    return this.getWithdrawPrice();
  }

  // TODO:used into OLD UI only
  /**
   * [R/R] returns price of one relative token unit in relative portfolio base token units
   * @deprecated price calculation for old portfolios UI. New UI uses getPriceRelative()
   * @param portfolio portfolio which base token will be taken to compare
   */
  public getOldRelativePrice(
    portfolio: Portfolio,
    currentAction: 'addLiquidity' | 'withdraw',
  ): BigNumber {
    let relativePrice: BigNumber;
    if (currentAction === 'addLiquidity') {
      relativePrice = this.getDepositPrice();
    } else {
      relativePrice = this.getWithdrawPrice();
    }
    return relativePrice;
  }
}

export class Portfolio {
  type: PortfolioSource;
  chainId: ChainId;
  portfolioId: number;
  name: string;
  contractAddress: string;
  baseTokenAddress: string;
  isBaseTokenPresentLocally?: boolean;
  baseToken: Token;
  lpTokenAddress: string;

  /**
   * [R] price of 1 LP per base token
   */
  // TODO: [W / W] price of 1 LP wei per base token wei
  lpTokenPriceBase: BigNumber;

  /**
   * [W] In base token wei units
   */
  totalValue: BigintIsh;
  tokenCount: BigNumber;
  tokens: TokenInfo[] = [];
  crossChainTokens: TokenInfo[] = [];
  tokensByAddr: { [k: string]: Token } = {};
  tokensInfoByAddr: { [k: string]: TokenInfo } = {};
  pairs: { [k: string]: Pair } = {};

  portfolioTotalValueBase: TokenAmount;

  volume30: TokenAmount;
  fee30: TokenAmount;
  priceUSDToken: Token;

  /**
   * [USD / R] base token relative unit price in USD
   */
  priceInUSD: BigNumber;

  /**
   * [W] In LP (18 decimals) wei units
   */
  totalSupply: BigNumber;

  withdrawTokens: { [k: string]: WithdrawTokens } = {};
  balanceOfWallet: BigNumber;
  balanceRestOfWallet: BigNumber;

  /**
   * [R] lp tokens number to withdraw in relative token units
   */
  withdrawAmount: BigNumber;

  get baseTokenInfo(): TokenInfo {
    return this.getTokenInfo(this.baseToken);
  }

  /**
   * [W] Total value in base token wei units.
   * totalValue + protocolFee === SUM(tokenPrice_i * tokenReserve_i)
   */
  get totalValueWithProtocolFee(): BigNumber {
    return Object.values(this.tokensInfoByAddr).reduce(
      (sum, token) => sum.plus(token.baseTokenAmountEquivalent),
      BIG_ZERO,
    );
  }

  constructor(portfolioInfo: PortfolioDto, portfolioType: PortfolioSource, currentChain: ChainId) {
    this.type = portfolioType;
    this.chainId = currentChain;
    this.name = portfolioInfo.name;
    this.portfolioId = portfolioInfo?.portfolioId;
    this.contractAddress = portfolioInfo.contractAddress;
    this.baseTokenAddress = portfolioInfo.baseTokenAddress;
    this.isBaseTokenPresentLocally = portfolioInfo.isBaseTokenPresentLocally;

    const { getTokenByAddressAndChainId } = useTokens();
    this.baseToken = getTokenByAddressAndChainId(this.baseTokenAddress, this.chainId);

    this.lpTokenAddress = portfolioInfo.lpTokenAddress;
    this.lpTokenPriceBase = fromWei(
      ethersToBigNumber(portfolioInfo.lpTokenPrice),
      PRICE_DECIMALS,
    ).shiftedBy(LP_TOKEN_DECIMALS - this.baseToken.decimals);
    this.totalValue = new TokenAmount(
      this.baseToken,
      ethersToBigNumber(portfolioInfo.totalValue).integerValue().toString(),
    ).raw;
    this.portfolioTotalValueBase = new TokenAmount(this.baseToken, this.totalValue);
    this.tokenCount = ethersToBigNumber(portfolioInfo.tokenCount);

    console.groupCollapsed(`=== Portfolio ${this.name} | ${this.portfolioId} | ${this.type} ===`);
    console.log('baseToken: ', `${this.baseToken.symbol} | ${this.baseToken.decimals}`);
    console.log(
      'lpTokenPriceBase (raw): ',
      ethersToBigNumber(portfolioInfo.lpTokenPrice).toString(),
    );
    console.log(
      `lpTokenPriceBase in [${this.baseToken.symbol}]: `,
      this.lpTokenPriceBase.toString(),
    );
    console.log('totalValue (raw): ', ethersToBigNumber(portfolioInfo.totalValue).toString());
    console.log('totalValue.raw: ', this.totalValue.toString());
    console.log('portfolioTotalValueBase (fixed): ', this.portfolioTotalValueBase.toFixed());
    console.log('portfolioTotalValueBase (exact): ', this.portfolioTotalValueBase.toExact());

    portfolioInfo.tokens.forEach(token => {
      if (token.isPresentLocally === false) return;
      this.tokens.push(new TokenInfo(token, this.baseToken));
    });
    portfolioInfo.tokens.forEach(token => {
      if (this.type === PortfolioSource.PORTFOLIO_LOCALE) return;
      if (token.isPresentLocally === true) return;
      this.crossChainTokens.push(new TokenInfo(token, this.baseToken));
    });

    this.tokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
      this.withdrawTokens[token.tokenAddress] = new WithdrawTokens(token);
    });
    this.crossChainTokens.forEach((token: TokenInfo) => {
      this.tokensByAddr[token.tokenAddress] = token.token;
      this.tokensInfoByAddr[token.tokenAddress] = token;
    });
    this.volume30 = new TokenAmount(this.baseToken, BN_ZERO);
    this.fee30 = new TokenAmount(this.baseToken, BN_ZERO);
    this.priceInUSD = BIG_ZERO;
    this.totalSupply = BIG_ZERO;
    this.balanceOfWallet = BIG_ZERO;
    this.balanceRestOfWallet = BIG_ZERO;
    this.withdrawAmount = BIG_ZERO;
    this.priceUSDToken = new Token(
      currentChain,
      ethers.constants.AddressZero,
      BOT_USD_DECIMALS,
      'USD',
    );
    console.log('portfolio constructor', this);

    console.groupEnd();
  }

  /**
   * set:
   *  - lpTokenPriceBase
   *  - totalValue
   *  - portfolioTotalValueBase
   *  - tokenCount
   */
  updatePortfolioInfo(portfolioInfo: PortfolioDto) {
    this.lpTokenPriceBase = fromWei(
      ethersToBigNumber(portfolioInfo.lpTokenPrice),
      PRICE_DECIMALS,
    ).shiftedBy(LP_TOKEN_DECIMALS - this.baseToken.decimals);
    this.totalValue = new TokenAmount(
      this.baseToken,
      ethersToBigNumber(portfolioInfo.totalValue).integerValue().toString(),
    ).raw;
    this.portfolioTotalValueBase = new TokenAmount(this.baseToken, this.totalValue);
    this.tokenCount = ethersToBigNumber(portfolioInfo.tokenCount);
  }

  getTokenInfo(token: string | Token): TokenInfo {
    const address = token instanceof Token ? token.address : token;

    const tokenInfo = this.tokens.find(item => item.tokenAddress === address);
    if (!tokenInfo) {
      throw new Error(`Token ${token} not found in the portfolio.`);
    }

    return tokenInfo;
  }

  /**
   * [ USD / R ] returns price of one relative token unit in usd
   * @param tokenInfo
   */
  getTokenPriceInUSD(tokenInfo: TokenInfo, currentAction = 'addLiquidity'): BigNumber {
    let relativePrice: BigNumber;
    if (currentAction === 'addLiquidity') {
      relativePrice = tokenInfo.getDepositPrice();
    } else {
      relativePrice = tokenInfo.getWithdrawPrice();
    }

    console.groupCollapsed(
      `=== Token [${currentAction}] `,
      `${(tokenInfo.token as any)?.symbol} | ${tokenInfo.token.decimals} `,
      `price in USD ===`,
    );
    console.log('baseToken : ', `${this.baseToken.symbol} | ${this.baseToken.decimals}`);
    console.log(
      `${(tokenInfo.token as any)?.symbol} ${currentAction} price in ${this.baseToken.symbol}: `,
      relativePrice.toString(),
    );
    console.log(`${this.baseToken.symbol} price In USD : `, this.priceInUSD.toString());
    console.log(
      `${(tokenInfo.token as any)?.symbol} price In USD : `,
      relativePrice.multipliedBy(this.priceInUSD).toString(),
    );
    console.groupEnd();

    return relativePrice.multipliedBy(this.priceInUSD);
  }

  addPriceUSDToken(token: Token) {
    this.priceUSDToken = token;
  }

  /**
   * set LP token (portfolio) total supply
   */
  addTotalSupply(value: BigNumber) {
    this.totalSupply = value;

    console.log(
      `PORTFOLIO [${this.name}] : `,
      `(${this.baseToken.symbol} | ${this.baseToken.decimals}) `,
      `| LP total supply : `,
      this.totalSupply.toString(),
    );
  }

  /**
   * set user LP token (portfolio) balance
   */
  addBalanceOfWallet(value: BigNumber) {
    this.balanceOfWallet = value;
    this.balanceRestOfWallet = value;

    console.log(
      `PORTFOLIO [${this.name}] : `,
      `(${this.baseToken.symbol} | ${this.baseToken.decimals}) `,
      `| LP balance of wallet : `,
      value.toString(),
    );
  }

  addVolume(value: BigintIsh) {
    // TODO: Need change when will do FSDEX-1887
    this.volume30 = new TokenAmount(this.priceUSDToken, value);
  }

  addFee(value: BigintIsh) {
    // TODO: Need change when will do FSDEX-1887
    this.fee30 = new TokenAmount(this.priceUSDToken, value);
  }

  addPriceInUSD(value: BigNumber) {
    // TODO: Need change when will do FSDEX-1887
    this.priceInUSD = fromWei(value, BOT_USD_DECIMALS);

    console.log(
      `PRICE API : `,
      `PORTFOLIO [${this.name}] : `,
      `(${this.baseToken.symbol} | ${this.baseToken.decimals}) `,
      `convert by decimals ( ${BOT_USD_DECIMALS} ) `,
      `[ USD token : (${this.priceUSDToken.symbol} | ${this.priceUSDToken.decimals}) ] `,
      ` ->  `,
      value.toString(),
      ` priceInUSD = `,
      this.priceInUSD.toString(),
    );
  }

  /**
   * [R]
   * @param value in relative units
   */
  getValueInUSD(value: string, priceValue?: BigNumber) {
    if (priceValue) {
      return this.priceInUSD.multipliedBy(value).multipliedBy(priceValue);
    }
    return this.priceInUSD.multipliedBy(value);
  }

  /**
   * [R]
   * @param value in relative units
   */
  getValueInBASE(value: string) {
    return new BigNumber(value).dividedBy(this.priceInUSD);
  }

  /**
   * [R] Calculates deposit amount in base token equivalent in relative units
   * @param tokensArray tokens and amounts in relative units
   * @param options calculation options
   */
  getYouWillDeposit(
    tokensArray: { [k: string]: FilledTokensObject },
    options: {
      ignoreBalanceErrors?: boolean;
      usePriceInsteadOfDepositPrice?: boolean;
    } = {},
  ): BigNumber {
    let willDepositBase = new BigNumber(0);
    if (!tokensArray) return willDepositBase;
    if (Object.values(tokensArray).some(token => token.hasError)) {
      if (
        !options.ignoreBalanceErrors ||
        Object.values(tokensArray).some(token => token.hasError && !token.hasOnlyBalanceError)
      )
        return willDepositBase;
    }
    Object.keys(tokensArray).forEach(key => {
      const foundToken = this.tokens.find(token => token.tokenAddress === key);
      if (foundToken) {
        const depositPrice = options.usePriceInsteadOfDepositPrice
          ? foundToken.price
          : foundToken.getDepositPrice();

        willDepositBase = willDepositBase.plus(
          depositPrice.multipliedBy(tokensArray[key].value || 0),
        );
      }
    });
    return willDepositBase;
  }

  /**
   * [R] Calculates deposit amount in USD equivalent in relative units
   * @param willDepositBase [R] value to deposit in relative base token units
   */
  getYouWillDepositUSD(willDepositBase: BigNumber): BigNumber {
    return toBigNumber(willDepositBase).multipliedBy(this.priceInUSD);
  }

  /**
   * [USD / R] price of one relative lp token unit in USD
   */
  getLpTokenPriceUSD(): BigNumber {
    return this.priceInUSD.multipliedBy(this.lpTokenPriceBase);
  }

  updateWithdrawTokenWithoutRecalculation(tokenAddress: string, checked: boolean) {
    this.withdrawTokens[tokenAddress].checked = checked;
  }

  /**
   * [R] user lp tokens balance in relative units
   */
  getBalanceOfLp(): BigNumber {
    return fromWei(this.balanceOfWallet, LP_TOKEN_DECIMALS);
  }

  /**
   * [R] user lp balance in relative base token units
   */
  getYouDepositBase(): BigNumber {
    return this.getBalanceOfLp().multipliedBy(this.lpTokenPriceBase);
  }

  /**
   * [R] user lp balance in USD
   */
  getYouDepositUSD(): BigNumber {
    return this.getYouDepositBase().multipliedBy(this.priceInUSD);
  }

  getYouPortfolioShare(): BigNumber {
    if (!this.totalSupply.gt(0)) {
      return new BigNumber(0);
    }

    return this.balanceOfWallet.dividedBy(this.totalSupply).multipliedBy(100);
  }

  /**
   * [R] returns summary withdraw value in base token relative units
   * @param withdrawTokenAmountsWei
   */
  getEstimatedWithdrawInBaseToken(withdrawTokenAmountsWei: string[]): BigNumber {
    const withdrawTokens = Object.values(this.withdrawTokens).filter(token => token.checked);

    const baseTokenEquivalentWei = withdrawTokens.reduce(
      (acc, token, index) =>
        acc.plus(
          token
            .getWithdrawPrice()
            .multipliedBy(withdrawTokenAmountsWei![index])
            .shiftedBy(this.baseToken.decimals - token.token.decimals), // to base decimals
        ),
      BIG_ZERO,
    );

    return fromWei(baseTokenEquivalentWei, this.baseToken.decimals);
  }

  setWithdrawAmountFromInput(value: string) {
    if (value === '') {
      value = '0';
    }

    this.withdrawAmount = new BigNumber(value);
  }

  // TODO: used into OldPortfolioCompound only
  /**
   * [R] Estimated LP to receive in relative units
   * @param tokensArray tokens and amounts in relative units
   */
  getPortfolioTokensReceived(tokensArray: { [k: string]: FilledTokensObject }): BigNumber {
    const totalInvestmentInRelativeUnits = this.getYouWillDeposit(tokensArray);
    const totalInvestmentInWei = toWei(totalInvestmentInRelativeUnits, this.baseToken.decimals);
    const willReceiveLPInWei = totalInvestmentInWei
      .dividedBy(this.lpTokenPriceBase)
      .shiftedBy(LP_TOKEN_DECIMALS - this.baseToken.decimals); // to LP decimals
    return fromWei(willReceiveLPInWei, LP_TOKEN_DECIMALS);
  }

  // TODO: used into OLD UI only
  /**
   * returns max(no_fee_amount_i, 0);
   *
   * no_fee_amount_i = ( // dividend
   *     (portfolios.totalValue + totalEnteredAmount - enteredAmount_i * price_i)
   *     * portfolios.tokens[i].targetWeight
   *     / price_i
   *     - portfolios.tokens[i].amount
   *  )
   *  / (1 - portfolios.tokens[i].targetWeight)
   * @returns [W] array of maximum non fee deposit amounts in wei
   */
  getMaximumDepositNonFeeAmount(tokensArray: Record<string, FilledTokensObject>): BigNumber[] {
    if (this.checkIfPortfolioIsInNonFeeMode()) {
      return Object.keys(tokensArray).map(() => BIG_ZERO);
    }

    const depositInBaseTokenWeiEquivalent = toWei(
      this.getYouWillDeposit(tokensArray, {
        ignoreBalanceErrors: true,
        usePriceInsteadOfDepositPrice: true,
      }),
      this.baseToken.decimals,
    );
    const portfolioFutureValueInBaseTokenWeiEquivalent = depositInBaseTokenWeiEquivalent.plus(
      this.totalValueWithProtocolFee,
    );

    return Object.entries(tokensArray).map(([address, filledInfo]) => {
      const targetTokenInfo = this.getTokenInfo(address);
      const depositPrice = targetTokenInfo.price;
      const targetTokenEnteredWeiAmount = toWei(
        filledInfo.value || '0',
        targetTokenInfo.token.decimals,
      );

      const targetTokenEnteredWeiAmountBaseEquivalent = targetTokenEnteredWeiAmount
        .multipliedBy(depositPrice)
        .shiftedBy(targetTokenInfo.baseToken.decimals - targetTokenInfo.token.decimals); // to base decimals

      const currentTokenAmountWei = targetTokenInfo.amount.raw.toString();
      const targetWeight = targetTokenInfo.targetWeight;
      const oneMinusTargetWeight = new BigNumber(1).minus(targetTokenInfo.targetWeight);

      const dividend = portfolioFutureValueInBaseTokenWeiEquivalent
        .minus(targetTokenEnteredWeiAmountBaseEquivalent)
        .multipliedBy(targetWeight)
        .div(depositPrice)
        .shiftedBy(targetTokenInfo.token.decimals - targetTokenInfo.baseToken.decimals) // to token decimals
        .minus(currentTokenAmountWei);

      return max(dividend.div(oneMinusTargetWeight).integerValue(BigNumber.ROUND_FLOOR), 0);
    });
  }

  // TODO: used into OLD UI only
  checkIfPortfolioIsInNonFeeMode(): boolean {
    const nonZeroAmountTokensNumber = this.tokens.filter(token =>
      token.amount.greaterThan('0'),
    ).length;

    return this.baseTokenInfo.amount.equalTo('0') || nonZeroAmountTokensNumber <= 1;
  }

  getWithdrawAmountFromPercent(percent) {
    return this.getBalanceOfLp().dividedBy(100).multipliedBy(percent);
  }

  // TODO: used into OLD UI only
  /**
   * old portfolios
   * returns flag if calculation was successful
   * @param lp_tokens_to_withdraw
   */
  async calculateWithdrawals(lp_tokens_to_withdraw: string): Promise<boolean> {
    this.setWithdrawAmountFromInput(lp_tokens_to_withdraw);

    const unCheckedTokens = Object.values(this.withdrawTokens).filter(wToken => !wToken.checked);
    unCheckedTokens.forEach(token => {
      token.updateLpValue(0);
      token.updateValue(0);
    });

    const checkedTokensInfo = this.getCheckedTokensInfo();

    if (!checkedTokensInfo.length || !Number(lp_tokens_to_withdraw)) {
      return true;
    }

    const tokenAddresses = checkedTokensInfo.map(token => token.tokenAddress);

    const tokenWithZeroWeight = checkedTokensInfo.find(tokenInfo => {
      return (
        this.getTokenWeightShare(tokenInfo).toFixed() === 'NaN' ||
        this.getTokenWeightShare(tokenInfo).toFixed() === '0'
      );
    });

    const lptAmounts = checkedTokensInfo.map(tokenInfo => {
      let lpTokenRelativeAmountToSellForCurrentToken;

      if (tokenWithZeroWeight) {
        lpTokenRelativeAmountToSellForCurrentToken = this.withdrawAmount.dividedBy(
          checkedTokensInfo.length,
        );
      } else {
        const tokenShareOfWithdrawAmount = this.getTokenWeightShare(tokenInfo);
        lpTokenRelativeAmountToSellForCurrentToken = tokenShareOfWithdrawAmount.multipliedBy(
          this.withdrawAmount,
        );
      }

      return toWei(lpTokenRelativeAmountToSellForCurrentToken, LP_TOKEN_DECIMALS).toFixed(
        0,
        BigNumber.ROUND_DOWN,
      );
    });

    checkedTokensInfo.forEach((tokenInfo, index) => {
      const withdrawToken = this.withdrawTokens[tokenInfo.tokenAddress];
      withdrawToken.updateValue(null);
      withdrawToken.updateLpValue(fromWei(lptAmounts[index], LP_TOKEN_DECIMALS));
    });

    //TODO make old ui work
    const weiWithdrawAmounts = checkedTokensInfo.map(() => '0');

    const calculationWasSuccessful = true;
    // try {
    //   weiWithdrawAmounts = await this.fetchExactTokenWeiAmountsAfterWithdraw({
    //     tokenAddresses,
    //     lptAmounts,
    //     portfolioAddress: this.contractAddress,
    //   });
    // } catch (e) {
    //   console.debug(e);
    //   calculationWasSuccessful = false;
    // }

    checkedTokensInfo.forEach((tokenInfo, index) => {
      const withdrawToken = this.withdrawTokens[tokenInfo.tokenAddress];
      withdrawToken.updateValue(fromWei(weiWithdrawAmounts[index], tokenInfo.token.decimals));
      withdrawToken.updateLpValue(fromWei(lptAmounts[index], LP_TOKEN_DECIMALS));
    });

    return calculationWasSuccessful;
  }

  // TODO: used into OLD UI only
  checkIfSomeWithdrawAmountExceedsReserve(): boolean {
    return Object.entries(this.withdrawTokens).some(([address, withdrawToken]) => {
      const tokenInfo: TokenInfo = this.tokens.find(token => token.tokenAddress === address)!;
      return withdrawToken.weiValue?.gt(tokenInfo.amount.raw.toString());
    });
  }

  // TODO: used into OLD UI only
  getCheckedTokensInfo(): TokenInfo[] {
    const checkedTokens = Object.values(this.withdrawTokens)
      .filter(wToken => wToken.checked)
      .map(t => t.tokenAddress);
    const unCheckedTokens = Object.values(this.withdrawTokens).filter(wToken => !wToken.checked);
    unCheckedTokens.forEach(token => {
      token.updateValue(0);
      token.updateLpValue(0);
    });

    return this.tokens.filter(token =>
      checkedTokens.some(checkedToken => compareTokenAddresses(checkedToken, token.token.address)),
    );
  }

  // TODO: used into OLD UI only
  getTokenWeightShare(tokenInfo: TokenInfo): BigNumber {
    const weightsSum = this.getCheckedTokensInfo().reduce(
      (acc, tokenInfo) => acc.plus(tokenInfo.targetWeight),
      BIG_ZERO,
    );

    return tokenInfo.targetWeight.div(weightsSum);
  }

  // TODO: used into old UI only
  /**
   * returns max(no_fee_amount_i, 0)
   *
   * no_fee_amount_i = ( // dividend
   *     portfolios.tokens[i].amount
   *     -  // subtrahend
   *        ( // firstMultiplier
   *            portfolios.totalValue
   *            - enteredLPAmount * LPTPrice
   *            + LPamount_i * LPTPrice
   *        )
   *        * portfolios.tokens[i].targetWeight
   *        / withdrawPrice_i
   *  )
   *  / ( 1 - portfolios.tokens[i].targetWeight )
   *  @returns [W] array of maximum non fee withdraw amounts in wei
   */
  getMaximumWithdrawNonFeeAmounts(): BigNumber[] {
    if (this.checkIfPortfolioIsInNonFeeMode()) {
      return Object.keys(this.withdrawTokens).map(() => BIG_ZERO);
    }

    const LPTPrice = this.lpTokenPriceBase;

    const withdrawLpWeiAmount = toWei(this.withdrawAmount, LP_TOKEN_DECIMALS);

    const portfolioWeiValueAfterWithdraw = toBigNumber(this.totalValue.toString()).minus(
      withdrawLpWeiAmount
        .multipliedBy(LPTPrice)
        .shiftedBy(this.baseToken.decimals - LP_TOKEN_DECIMALS), // to base decimals
    );

    return Object.entries(this.withdrawTokens).map(([address, withdrawToken]) => {
      const targetTokenInfo = this.getTokenInfo(address);
      const oneMinusTargetWeight = new BigNumber(1).minus(targetTokenInfo.targetWeight);
      const tokenIAmount = toBigNumber(targetTokenInfo.amount.raw.toString());

      const firstMultiplier = portfolioWeiValueAfterWithdraw.plus(
        withdrawToken.lpWeiValue
          .multipliedBy(LPTPrice)
          .shiftedBy(this.baseToken.decimals - LP_TOKEN_DECIMALS), // to base decimals
      );

      const subtrahend = firstMultiplier
        .multipliedBy(targetTokenInfo.targetWeight)
        .div(withdrawToken.getWithdrawPrice())
        .shiftedBy(targetTokenInfo.token.decimals - this.baseToken.decimals); // to base decimals

      const dividend = tokenIAmount.minus(subtrahend);

      return max(dividend.div(oneMinusTargetWeight).integerValue(BigNumber.ROUND_FLOOR), 0);
    });
  }
}
