export enum PairSourceType {
  EMPTY = 'EMPTY',
  PORTFOLIO = 'PORTFOLIO',
  CROSSCHAIN_PORTFOLIO = 'CROSSCHAIN_PORTFOLIO',
}

export interface PairSource {
  type: PairSourceType;
  address: string;
  id: number | undefined;
}
