export function useLocalStorage() {
  const getItem = <T>(key: string): T | null => {
    return localStorage.getItem(key) as T | null;
  };

  const setItem = (key: string, value: string): void => {
    return localStorage.setItem(key, value);
  };

  const removeItem = (key: string): void => {
    return localStorage.removeItem(key);
  };

  const dispatchStorageEvent = (key: string) => {
    window.dispatchEvent(
      new CustomEvent('localStorageChange', {
        detail: {
          key: key,
          value: getItem(key),
        },
      }),
    );
  };

  return {
    getItem,
    setItem,
    removeItem,
    dispatchStorageEvent,
  };
}
