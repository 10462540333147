import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { SerializedBigNumber } from '@/sdk/constants';

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);
export const BIG_NINE = new BigNumber(9);
export const BIG_TEN = new BigNumber(10);
export const BIG_100 = new BigNumber(100);

export const ethersToBigNumber = (ethersBn: ethers.BigNumber): BigNumber =>
  new BigNumber(ethersBn.toString());

export const ethersToSerializedBigNumber = (ethersBn: ethers.BigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON();

export function toBigNumber(value: string | number | BigNumber): BigNumber {
  return new BigNumber(value);
}

export function min(
  value1: string | number | BigNumber,
  value2: string | number | BigNumber,
): BigNumber {
  const bn1 = toBigNumber(value1);
  const bn2 = toBigNumber(value2);

  return bn1.isLessThanOrEqualTo(bn2) ? bn1 : bn2;
}

export function max(
  value1: string | number | BigNumber,
  value2: string | number | BigNumber,
): BigNumber {
  const bn1 = toBigNumber(value1);
  const bn2 = toBigNumber(value2);

  return bn1.isGreaterThanOrEqualTo(bn2) ? bn1 : bn2;
}
