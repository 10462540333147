import { Token } from '@/sdk/entities/token';
import { SelectedPortfolioTokens } from '@/store/modules/portfolios/models/selected-portfolio-tokens.interface';
import { Portfolio } from '@/sdk/entities/portfolio';
import { compareTokenAddresses, toWei } from '@/sdk/utils';
import { EasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { getPortfolioAndPairRegistryContract } from './contract.helper';
import { estimateDeposit } from './cross-chain-api';

const DEFAULT_DEPOSIT_METHOD_PARAMETERS_OPTIONS = {
  ignoreBalanceErrors: false,
};

/**
 * Prepare params for estimate deposit.
 * Will filter `selectedTokens` - skip token with zero amount.
 */
export function getDepositMethodParameters(
  portfolio: Portfolio,
  selectedTokens: SelectedPortfolioTokens,
  options: {
    ignoreBalanceErrors?: boolean;
  } = DEFAULT_DEPOSIT_METHOD_PARAMETERS_OPTIONS,
): {
  tokens: Token[];
  amountsIn: string[];
  portfolioAddress: string;
} {
  options = { ...DEFAULT_DEPOSIT_METHOD_PARAMETERS_OPTIONS, ...options };
  const tokens: Token[] = [];
  const amountsIn: string[] = [];

  if (Object.values(selectedTokens).some(token => token.hasError)) {
    if (
      !options.ignoreBalanceErrors ||
      Object.values(selectedTokens).some(token => token.hasError && !token.hasOnlyBalanceError)
    )
      throw new Error(`There is an error in some token`);
  }

  Object.entries(selectedTokens).forEach(([tokenAddress, selection]) => {
    if (selection.value !== '') {
      const tokenInfo = portfolio.tokens.find(tokenInfo =>
        compareTokenAddresses(tokenInfo.token.address, tokenAddress),
      );
      if (!tokenInfo) {
        throw new Error(`Token ${tokenAddress} was not found.`);
      }

      // NOTE: We are filtering tokens with zero amount.
      const amountIn = toWei(selection.value, tokenInfo.token.decimals);
      if (amountIn.isZero()) {
        return;
      }

      tokens.push(tokenInfo.token);
      amountsIn.push(amountIn.toFixed(0));
    }
  });

  return {
    tokens,
    amountsIn,
    portfolioAddress: portfolio.contractAddress,
  };
}

/**
 *
 * @return LP amount in LP wei units
 */
export async function fetchExactLpWeiAmountAfterDeposit(
  portfolio: Portfolio,
  selectedTokens: SelectedPortfolioTokens,
  easyModeForm: EasyModeForm,
): Promise<string> {
  const parameters = getDepositMethodParameters(portfolio, selectedTokens, {
    ignoreBalanceErrors: true,
  });

  let bnAmount;
  console.log('fetchExactLpWeiAmountAfterDeposit', parameters);
  if (portfolio.type === PortfolioSource.PORTFOLIO_LOCALE) {
    // NOTE: amountsIn should be in token`s decimals
    const registryContract = getPortfolioAndPairRegistryContract();

    bnAmount = await registryContract.callStatic.estimateDeposit(
      parameters.tokens.map(token => token.address),
      parameters.amountsIn,
      parameters.portfolioAddress,
    );
  } else {
    // NOTE: amountsIn should be in token`s decimals
    const response = await estimateDeposit(
      portfolio.portfolioId,
      parameters.tokens
        .map((token, index) => {
          // NOTE: token symbols - should be crosschain symbols
          return `${parameters.amountsIn[index]}${token.crossChainSymbol}`;
        })
        .join(','),
      easyModeForm.sourceChainId,
      easyModeForm.destinationChainId,
    );
    bnAmount = response.totalLiquidityOut;
  }

  console.log('fetchExactLpWeiAmountAfterDeposit bnAmount', bnAmount.toString());
  return bnAmount.toString();
}
