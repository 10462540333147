import { getInstance } from '@snapshot-labs/lock/plugins/vue3';
import {
  getAutoPoolContract,
  getErc20Contract,
  getMinterAprWeights,
  transactionWithEstimatedGas,
} from '@/helpers/contract.helper';
import {
  BN_ZERO,
  ethersToJSBI,
  SOLIDITY_TYPE_MAXIMA,
  SolidityType,
  BOT_USD_DECIMALS,
} from '@/sdk/constants';
import JSBI from 'jsbi';
import { parseUnits } from '@ethersproject/units';
import { AutoStaking } from '@/sdk/entities/autoStaking';
import { getAutoPoolAddress } from '@/helpers/address.helper';
import { GLOBAL_GETTERS } from '@/store/modules/global.getters';
import { fromWei } from '@/sdk/utils';
import { NETWORK_BACKEND_URL } from '@/helpers/networkParams.helper';
import { dateNowInSeconds } from '@/helpers/utils';

export const AUTO_STAKING_ACTION_TYPES = {
  AUTO_STAKING_INIT: 'autoStakingInit',
  GET_STATUS_AUTO_STAKING: 'getStatusAutoStaking',
  UPDATE_AUTO_STAKING: 'updateAutoStaking',
  CHECK_ALLOWANCE_AUTO_STAKING: 'checkAllowanceAutoStaking',
  STAKE_LQF_AUTO_STAKING: 'stakeLqfAutoStaking',
  UNSTAKE_LQF_AUTO_STAKING: 'unStakeLqfAutoStaking',
  UNSTAKE_MAX_LQF_AUTO_STAKING: 'unStakeMaxLqfAutoStaking',
  CLAIM_AUTO_STAKING: 'claimAutoStaking',
  GET_APR_WEIGHTS: 'getAprWeights',
  GET_NEW_REWARDS: 'getNewRewards',
  GET_HODLER_APR: 'getHodlerApr',
  GET_TOTAL_COMPOUND: 'getTotalCompound',
  GET_AVAILABLE_COMPOUND: 'getAvailableCompound',
  GET_AVAILABLE_WITH_COMPOUND_TOTAL: 'getAvailableWithCompoundTotal',
  GET_DIVISOR: 'getDivisor',
  UPDATE_INFO: 'updateInfo',
};

export const AUTO_STAKING_MUTATION_TYPES = {
  SET_STATE: 'setState',
  ADD_AUTO_POOL: 'addAutoPool',
};

const state = {
  isStoreReady: false,
  hasError: false,
  statusAutoStaking: null,
  autoPool: null,
  aprWeights: [],
  newRewards: null,
  hodlerApr: null,
  totalCompound: null,
  availableCompound: null,
  availableWithCompoundTotal: null,
  getDivisor: null,
};
const getters = {
  getAutoStakingTableData: state => {
    console.log('state.autoPool', state.autoPool);
    if (!state.autoPool) {
      return [];
    }

    return [state.autoPool];
  },
};
const actions = {
  async [AUTO_STAKING_ACTION_TYPES.AUTO_STAKING_INIT]({ commit, dispatch }) {
    commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, {
      isStoreReady: false,
      hasError: false,
      statusAutoStaking: null,
      autoPool: null,
    });
    try {
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_APR_WEIGHTS);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_NEW_REWARDS);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_HODLER_APR);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_TOTAL_COMPOUND);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_COMPOUND);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_WITH_COMPOUND_TOTAL);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_DIVISOR);
      await dispatch(AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING);
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { isStoreReady: true });
    } catch (e) {
      console.error('[AUTO STAKING] Happen error: ', e);
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { hasError: true });
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.UPDATE_INFO]({ commit, dispatch }) {
    try {
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_NEW_REWARDS);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_HODLER_APR);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_TOTAL_COMPOUND);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_COMPOUND);
      await dispatch(AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_WITH_COMPOUND_TOTAL);
    } catch (e) {
      console.error('[AUTO STAKING] Happened error: ', e);
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { hasError: true });
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_STATUS_AUTO_STAKING]({ commit, rootState }) {
    console.log('AUTO_STAKING_ACTION_TYPES.GET_STATUS_STAKING START');
    try {
      const autoPoolContract = getAutoPoolContract();
      const statusAutoStaking = await autoPoolContract.getStatus(
        rootState.wallet.account,
        dateNowInSeconds(),
      );
      console.log('statusAutoStaking', statusAutoStaking);
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { statusAutoStaking });
    } catch (e) {
      console.debug(e);
    }
    console.log('AUTO_STAKING_ACTION_TYPES.GET_STATUS_STAKING END');
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_APR_WEIGHTS]({ commit }) {
    try {
      const aprWeights = await getMinterAprWeights();
      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { aprWeights });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_NEW_REWARDS]({ commit, rootState }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const newRewards = await autoPoolContract.earnedSinceLastAction(
        rootState.wallet.account,
        dateNowInSeconds(),
      );

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { newRewards });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_HODLER_APR]({ commit }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const hodlerApr = await autoPoolContract.aprL();

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { hodlerApr });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_TOTAL_COMPOUND]({ commit, rootState }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const totalCompound = await autoPoolContract.totalAssets(
        rootState.wallet.account,
        dateNowInSeconds(),
      );

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { totalCompound });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_COMPOUND]({ commit, rootState }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const availableCompound = await autoPoolContract.unstakeAvailable(
        rootState.wallet.account,
        dateNowInSeconds(),
      );

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { availableCompound });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_AVAILABLE_WITH_COMPOUND_TOTAL]({ commit }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const availableWithCompoundTotal = await autoPoolContract.availableWithCompoundTotal(
        dateNowInSeconds(),
      );

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { availableWithCompoundTotal });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.GET_DIVISOR]({ commit }) {
    try {
      const autoPoolContract = getAutoPoolContract();
      const divisor = await autoPoolContract.divisor();

      commit(AUTO_STAKING_MUTATION_TYPES.SET_STATE, { divisor });
    } catch (e) {
      console.debug(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING]({ state, commit, rootGetters }) {
    console.log('AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING_POOLS START');
    const baseTokenAddress = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN_ADDRESS];
    // TODO: we use this token like USD token (USD decimals for `getPrices` API)
    // const baseToken = rootGetters[GLOBAL_GETTERS.GET_BASE_TOKEN];
    const nativeToken = rootGetters[GLOBAL_GETTERS.GET_NATIVE_TOKEN];
    const pricesRequest = await fetch(`${NETWORK_BACKEND_URL + 'pool/getPrices'}`, {
      method: 'post',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        signature: false,
        tokens: [nativeToken.address],
        baseToken: baseTokenAddress,
      }),
    });
    const pricesResponse = await pricesRequest.json();
    console.log('pricesResponse', pricesResponse);
    const statusAutoStakingArr = state.statusAutoStaking ? [state.statusAutoStaking] : [];
    const aprWeights = state.aprWeights;
    const newRewards = state.newRewards;
    const hodlerAPR = state.hodlerApr;
    const totalCompound = state.totalCompound;
    const availableCompound = state.availableCompound;
    const availableWithCompoundTotal = state.availableWithCompoundTotal;
    const divisor = state.divisor;

    await Promise.all(
      statusAutoStakingArr.map(async statusAutoStaking => {
        // price: native token (BLUES) in USD
        const nativeTokenPriceInBase = fromWei(pricesResponse.price[0], BOT_USD_DECIMALS);
        const newAutoStaking = new AutoStaking(
          statusAutoStaking,
          nativeTokenPriceInBase,
          getAutoPoolAddress(),
          aprWeights,
          newRewards,
          hodlerAPR,
          totalCompound,
          availableCompound,
          availableWithCompoundTotal,
          divisor,
        );

        commit(AUTO_STAKING_MUTATION_TYPES.ADD_AUTO_POOL, newAutoStaking);
      }),
    );
    console.log('AUTO_STAKING_ACTION_TYPES.UPDATE_AUTO_STAKING_POOLS END');
  },
  async [AUTO_STAKING_ACTION_TYPES.CHECK_ALLOWANCE_AUTO_STAKING](
    { rootState },
    { pool, amount },
  ): Promise<void> {
    try {
      if (!getInstance()?.web3) {
        console.warn('[AUTO:STAKING:CHECK:ALLOWANCE] Can not get signer.');
        return;
      }
      console.log('[AUTO:STAKING:CHECK:ALLOWANCE]: pool: ', pool);

      const tokenContract = getErc20Contract(pool.token, getInstance().web3.getSigner());
      const allowance = await tokenContract.allowance(rootState.wallet.account, pool.farm);
      const jsbiAllowance = ethersToJSBI(allowance);
      const jsbiAmount = ethersToJSBI(parseUnits(amount ?? '0'));

      const needApprove =
        JSBI.equal(jsbiAllowance, BN_ZERO) || JSBI.greaterThan(jsbiAmount, jsbiAllowance);
      console.groupCollapsed(
        `[AUTO:STAKING:CHECK:ALLOWANCE] Need approve ${pool.token} : ${needApprove}`,
      );
      console.log(`${pool.token}     input: `, jsbiAmount.toString());
      console.log(`${pool.token} allowance: `, jsbiAllowance.toString());
      console.groupEnd();

      if (needApprove) {
        const approveRequest = await transactionWithEstimatedGas(tokenContract, 'approve', [
          pool.farm,
          SOLIDITY_TYPE_MAXIMA[SolidityType.uint256].toString(),
        ]);

        await approveRequest.wait();
      }
    } catch (ex) {
      console.debug('[AUTO:STAKING:CHECK:ALLOWANCE:ERROR] Error: ', ex);
      throw ex;
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.STAKE_LQF_AUTO_STAKING](_: any, { pool, amount }: any) {
    console.log('AUTO_STAKING_ACTION_TYPES.STAKE_LQF_AUTO_STAKING', pool, amount);
    try {
      const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());
      const bigIntAmount = parseUnits(amount).toString();
      const result = await transactionWithEstimatedGas(autoStakingContract, 'deposit', [
        bigIntAmount,
      ]);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.UNSTAKE_LQF_AUTO_STAKING](_: any, { pool, amount }) {
    console.log('AUTO_STAKING_ACTION_TYPES.UNSTAKE_LQF_AUTO_STAKING', pool, amount);
    try {
      const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());
      const bigIntAmount = parseUnits(amount).toString();
      const result = await transactionWithEstimatedGas(autoStakingContract, 'withdraw', [
        bigIntAmount,
      ]);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.UNSTAKE_MAX_LQF_AUTO_STAKING](_: any, { pool }) {
    console.log('AUTO_STAKING_ACTION_TYPES.UNSTAKE_MAX_LQF_AUTO_STAKING', pool);
    try {
      const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());
      const result = await transactionWithEstimatedGas(autoStakingContract, 'withdrawAll', []);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
  async [AUTO_STAKING_ACTION_TYPES.CLAIM_AUTO_STAKING]() {
    console.log('AUTO_STAKING_ACTION_TYPES.CLAIM_AUTO_STAKING');
    try {
      const autoStakingContract = getAutoPoolContract(getInstance().web3.getSigner());
      const result = await transactionWithEstimatedGas(autoStakingContract, 'claim', []);
      await result.wait().then(function (result) {
        Promise.resolve(result);
      });
    } catch (e) {
      console.debug(e);
      await Promise.reject(e);
    }
  },
};
const mutations = {
  [AUTO_STAKING_MUTATION_TYPES.SET_STATE](_state, payload) {
    Object.keys(payload).forEach(key => {
      _state[key] = payload[key];
    });
  },
  [AUTO_STAKING_MUTATION_TYPES.ADD_AUTO_POOL](_state, payload) {
    _state.autoPool = payload;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
