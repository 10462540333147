import { EasyModeForm } from '@/views/pages/liquidity/portfolios/portfolio/liquidity-management/easy-mode/models/easy-mode-form';
import { Portfolio, WithdrawTokens } from '@/sdk/entities/portfolio';
import { PortfolioSource } from '@/sdk/entities/PortfolioSource';
import { getPortfolioAndPairRegistryContract } from './contract.helper';
import { estimateWithdraw } from './cross-chain-api';

export function getWithdrawMethodParameters(
  portfolio: Portfolio,
  easyModeForm?: EasyModeForm,
): {
  tokenAddresses: string[];
  lptAmounts: string[];
  portfolioAddress: string;
  easyModeForm?: EasyModeForm;
} {
  const tokenAddresses: string[] = [];
  const lptAmounts: string[] = [];

  const checkedTokens = Object.values<WithdrawTokens>(portfolio.withdrawTokens).filter(
    token => token.checked,
  );
  checkedTokens.forEach(token => {
    const lpTAmountWei = token.lpWeiValue;
    if (!token.lpWeiValue) {
      throw new Error('Withdraw token lp value is null');
    }

    // NOTE: We are filtering tokens with zero amount.
    if (lpTAmountWei.isZero()) {
      return;
    }

    tokenAddresses.push(token.tokenAddress);
    lptAmounts.push(lpTAmountWei!.toFixed(0));
  });

  return {
    tokenAddresses,
    lptAmounts,
    portfolioAddress: portfolio.contractAddress,
    easyModeForm,
  };
}

export async function fetchExactTokenWeiAmountsAfterWithdraw(
  portfolio: Portfolio,
  easyModeForm: EasyModeForm,
  parameters?: {
    tokenAddresses: string[];
    lptAmounts: string[];
    portfolioAddress: string;
  },
): Promise<string[]> {
  parameters ||= getWithdrawMethodParameters(portfolio, easyModeForm);

  let bnAmounts;
  console.log('fetchExactTokenWeiAmountsAfterWithdraw', parameters);
  if (portfolio.type === PortfolioSource.PORTFOLIO_LOCALE) {
    const registryContract = getPortfolioAndPairRegistryContract();
    // NOTE: LOCAL: amounts in token`s decimals
    bnAmounts = await registryContract.callStatic.estimateWithdraw(
      parameters.tokenAddresses,
      parameters.lptAmounts,
      parameters.portfolioAddress,
    );
  } else {
    // TODO: need add throw Error when get data about incorrect request.
    // NOTE: CROSSCHAIN: amounts in token`s decimals
    const response = await estimateWithdraw(
      portfolio.portfolioId,
      parameters.tokenAddresses
        .map(tokenAddress => {
          // NOTE: token symbols - should be crosschain symbols
          return portfolio.tokensInfoByAddr[tokenAddress].token.crossChainSymbol;
        })
        .join(','),
      parameters.lptAmounts.join(','),
      easyModeForm.sourceChainId,
      easyModeForm.destinationChainId,
    );
    bnAmounts = response.coins.map(coin => coin.amount);
  }
  console.log(
    'fetchExactTokenWeiAmountsAfterWithdraw bnAmounts',
    bnAmounts.map(amount => amount.toString()),
  );
  return bnAmounts.map(amount => amount.toString());
}
