import LockConnector from '@snapshot-labs/lock/src/connector';

export default class Connector extends LockConnector {
  // See: https://eips.ethereum.org/EIPS/eip-5749
  get evmProvider() {
    return window['evmproviders']?.['nufi'] ?? window['ethereum'];
  }

  async connect() {
    const provider = this.evmProvider;
    try {
      await provider.request({ method: 'eth_requestAccounts' });
    } catch (e) {
      console.error(e);
      if (e.code === 4001) return;
    }

    return provider;
  }

  async isLoggedIn() {
    const provider = this.evmProvider;

    if (!provider) return false;
    let [selectedAddress] = await provider.request({ method: 'eth_accounts' });
    if (selectedAddress) return true;
    await new Promise(r => setTimeout(r, 400));
    [selectedAddress] = await provider.request({ method: 'eth_accounts' });
    return !!selectedAddress;
  }
}
